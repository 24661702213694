import React from 'react';
import styles from "./Circle.module.css";

export default function Circle({
  size = 'small',
  color,
  image,
  multicolor,
  imageContain=false,
  ...props
}) {

  const className = multicolor === true
    ? `${styles[size]} ${styles.multicolor}`
    : styles[size];

  let style =
    image !== null &&
    image !== undefined
      ? { background: `url('${image}') center center`}
      : { backgroundColor: color };
  
  style.backgroundSize = imageContain ? 'contain' : 'auto'
  
  return (
    <div
      className={className}
      style={style}
      {...props}
    />
  );
}
