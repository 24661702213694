.hidden {
  position: absolute;
  left: 100%;
  top: 100%;
}

.configurator {
  position: static;
  transform: translate(100%,100%);
  width: 100%;
  height: 100%;
}

.loaded {
  transform: translate(0,0);
}

.overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 12;
  inset: 0;
  padding: 0 1rem;
  background: var(--main-container-background-color);
}

.loadingContainer {
  width: 24rem;
}

a.download {
  font-size: .875rem;
  text-decoration: underline;
}

a.download:hover {
  cursor: pointer;
  text-decoration: none;
}
