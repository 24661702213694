export default {
  right: {
    selection: null, //signature | text | image
    signature: null,
    signatureColor: null,
    text: '',
    textColor: null,
    imageFile: [],
    base64: null,
  },
  left:{
    selection: null, //signature | text | image
    text: '',
    textColor: null,
    imageFile: [],
    base64: null,
  }
}
