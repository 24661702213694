.stack {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.valign-start {
  composes: stack;
  justify-content: flex-start;
}

.valign-center {
  composes: stack;
  justify-content: center;
}

.valign-end {
  composes: stack;
  justify-content: flex-end;
}

.valign-space-between {
  composes: stack;
  justify-content: space-between;
}


.halign-start {
  composes: stack;
  align-items: flex-start;
}

.halign-center {
  composes: stack;
  align-items: center;
}

.halign-end {
  composes: stack;
  align-items: flex-end;
}


.gap-none {
  composes: stack;
  composes: gap-none from '../style.module.css';
}

.gap-normal {
  composes: stack;
  composes: gap-normal from '../style.module.css';
}

.gap-medium {
  composes: stack;
  composes: gap-medium from '../style.module.css';
}

.gap-large {
  composes: stack;
  composes: gap-large from '../style.module.css';
}

.gap-xlarge {
  composes: stack;
  composes: gap-xlarge from '../style.module.css';
}
