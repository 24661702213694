import React, {useState} from 'react';
import FileUpload from 'react-drag-n-drop-image';
import styles from "./ImageUploader.module.css";
import Label from "../atoms/Label";

export default function ImageUploader({
 files= [],
 onChange
}) {

  /*
  const onRemoveImage = id => {
    setFiles(prev => prev.filter(i => i.id !== id));
  };
  */

  const onError = error => {
    console.error(error);
  };

  const handleOnChange = (images) => {
    const image = images[0];
    const reader = new FileReader();
    reader.readAsDataURL(image.file);
    reader.onloadend = () => {
      onChange(images, reader.result);
    }
  };


  return (
    <div className={styles.fileUploadContainer}>
      <FileUpload
        onChange={handleOnChange}
        body={<DropZone files={files} />}
        fileValue={files}
        maxSize={3}
        overlap={true}
        type={['jpg', 'jpeg', 'png','gif']}
      />
    </div>
  );
}

function DropZone({
  files
}) {

  return (
    <div className={styles.dropZone}>
      <Label size="small" spacing="medium" weight="normal">INSERISCI IMMAGINE</Label>
      {files.length > 0 && (
        <img key={files[0].id} alt='immagine' src={files[0].preview} />
      )}
    </div>
  );
}
