import styles from "./Navigation.module.css";
import Inline from "../atoms/Inline";
import BackIcon from "../icons/BackIcon";
import Label from "../atoms/Label";
import Button from "../atoms/Button";
import ResetIcon from "../icons/ResetIcon";
import useAppStore from "../hooks/useAppStore";
import Progress from "../atoms/Progress";
import {itemStatus} from "./ConfigurationItem";
import {useTranslation} from "react-i18next";

export default function Navigation({
  ...props
}) {

  const { t, i18n } = useTranslation();

  const {
    modelConfiguration, currentMaterials, currentGroup,
    setCurrentGroup, toggleColorWarningModal, toggleResetModal
  } = useAppStore( (state) => ({
    modelConfiguration: state.modelConfiguration,
    setCurrentGroup: state.setCurrentGroup,
    currentGroup: state.currentGroup,
    currentMaterials: state.currentMaterials,
    setCurrentMaterials: state.setCurrentMaterials,
    toggleResetModal: state.toggleResetModal,
    toggleShowRecap: state.toggleShowRecap,
    toggleColorWarningModal: state.toggleColorWarningModal,
  }));

  const completed = currentMaterials.length >= modelConfiguration.configurableGroups.length - 1;
  const index = modelConfiguration.configurableGroups.findIndex( group => currentGroup && group.name === currentGroup.name);
  const first = index === 0;
  const currentIndex = modelConfiguration.configurableGroups.findIndex(group => currentGroup && group.name === currentGroup.name);
  const last = currentIndex === modelConfiguration.configurableGroups.length - 1;

  const currentGroupStatus = currentMaterials.find( material => currentGroup && material.group.name === currentGroup.name)
      ? itemStatus.done
      : itemStatus.todo

  const buttonContainerClassName = `${styles.buttonContainer} ${first ? styles.disabled : null}`;
  const buttonResetContainerClassName = `${styles.buttonContainer} ${currentMaterials.length === 0 ? styles.disabled : null}`;

  const total = modelConfiguration.configurableGroups.length;
  const progress = ( (currentIndex+1) * 100) / total;
  //const progress = ( (currentMaterials.length) * 100) / total;

  const nextDisabled =
    currentGroup === null ||
    currentMaterials.length === 0 ||
    (currentGroupStatus === itemStatus.todo && currentGroup.id !== 'text-image') ;

  const back = (event) => {
    if(currentIndex <= 0) return;
    setCurrentGroup(modelConfiguration.configurableGroups[currentIndex - 1]);
  };

  const next = (event) => {

    //All steps completed, show colors warning modal
    if(last && completed) {
      //toggleShowRecap();
      toggleColorWarningModal();
      return;
    }

    if(currentIndex === modelConfiguration.configurableGroups.length - 1) return;

    setCurrentGroup(modelConfiguration.configurableGroups[currentIndex + 1]);
  };

  return (
    <>
      <div className={styles.progress}>
        <Progress progress={progress}/>
      </div>

      <div className={styles.navigation}>

        <Inline align="space-between" verticalAlign="center">
          <div className={buttonContainerClassName}>
            <button
              onClick={back}
              disabled={first}
              className={styles.button}
            >
              <Inline verticalAlign="center">
                <BackIcon />
                <div className={styles.backLabel}>
                  <Label>{t('button.back')}</Label>
                </div>
              </Inline>
            </button>
          </div>

          <Button
            disabled={nextDisabled}
            onClick={next}
          >
            {t('button.next')}
          </Button>

          <div className={buttonResetContainerClassName}>
            <button
              onClick={toggleResetModal}
              disabled={currentMaterials.length === 0}
              className={styles.button}
            >
              <Inline verticalAlign="center">
                <div className={styles.resetLabel}>
                  <Label>{t('button.reset')}</Label>
                </div>
                <ResetIcon/>
              </Inline>
            </button>
          </div>

        </Inline>
      </div>
    </>
  );
}
