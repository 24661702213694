import React, {Children} from "react";
import styles from "./Stack.module.css";

export default function Stack({
  valign='start',
  halign= 'start',
  gap = 'normal',
  children,
  ...props
}) {

  const className = `${styles[`halign-${halign}`]} ${styles[`valign-${valign}`]} ${styles[`gap-${gap}`]}`

  return (
    <div className={className} {...props} >
      {children}
    </div>
  )
}
