import designGroup from "./designGroup";
import paintGroup from "./paintGroup";
import bikes from "./bikes";

const camera = {
  telaio : {
    position: [-1.2972676753997803,0.43672266602516174,-1.904888391494751],
    target: [-0.1159,0.4974,-0.1523],
    up: [0,1,0],
    fov: 30,
    transitionTime: 1000
  },
  stickers:  {
    position: [1.4225021600723267,0.43672266602516174,-1.6117218732833862],
    target: [0.0112,0.4974,-0.0383],
    up: [0, 1, 0],
    fov: 34,
    transitionTime: 1000
  },
  linee: {
    position: [-0.0593,0.4409,-2.3433],
    target: [0,0.5016,-0.2305],
    up: [0, 1, 0],
    fov: 30,
    transitionTime: 1000
  },
  loghi: {
    position: [-0.0593,0.4409,-2.3433],
    target: [0,0.5016,-0.2305],
    up: [0, 1, 0],
    fov: 30,
    transitionTime: 1000
  },
  text: {
    position: [1.0525034666061401,0.43672266602516174,-1.877610445022583],
    target: [0.0112,0.4974,-0.0383],
    up: [0, 1, 0],
    fov: 34,
    transitionTime: 1000
  }
};

export default {
  name: bikes.theone,
  price: "380€",
  id: "IS6UYHAU0W",
  saddleCamera: {
    left:{
      position: [-0.06524023413658142,0.5443689823150635,2.525517463684082],
      target: [0.2073,0.6771,-0.210],
      up: [0, 1, 0],
      fov: 4,
      transitionTime: 1000
    },
    right: {
      position: [-0.06459453701972961,0.8319902420043945,-2.1539466381073],
      target: [0.2597714066505432,0.6129999756813049,1.0325000286102295],
      up: [0, 1, 0],
      fov: 5,
      transitionTime: 1000
    }
  },
  screenshotCamera: {
    ...camera.telaio,
    fov: 38
  },
  configurableGroups: [
    {
      ...designGroup,
      camera: camera.loghi
    },
    {
      ...paintGroup,
      camera: camera.loghi
    },
    {
      id: 11,
      name: "Telaio",
      label: "TELAIO",
      selectorType: "color",
      camera: camera.telaio,
      configurations: [
        {
          "id": 844,
          "name": "Carbon",
          "code": "MC_CRO_S",
          "color": null,
          "image": "https://cdn.emersya.com/cl/34WHTJDM76/p/HM9E2JX5YE/MMBJPLXUA146?date=1724851133"
        },
        {
          "id": 849,
          "name": "Silver",
          "code": "MC_SLE_S",
          "color": "#a8aaab",
          "image": null
        },
        {
          "id": 851,
          "name": "Dark Grey",
          "code": "MC_DRG_S",
          "color": "#6d6f70",
          "image": null
        },
        {
          "id": 853,
          "name": "Antracite",
          "code": "MC_ATRT_S",
          "color": "#6b6a67",
          "image": null
        },
        {
          "id": 855,
          "name": "Ocher",
          "code": "MC_OHR_S",
          "color": "#89802e",
          "image": null
        },
        {
          "id": 857,
          "name": "Gold",
          "code": "MC_GL_S",
          "color": "#a28135",
          "image": null
        },
        {
          "id": 859,
          "name": "Lime",
          "code": "MC_LM_S",
          "color": "#b1de00",
          "image": null
        },
        {
          "id": 861,
          "name": "Sand",
          "code": "MC_SN_S",
          "color": "#dcce90",
          "image": null
        },
        {
          "id": 863,
          "name": "Ottanium",
          "code": "MC_OTN_S",
          "color": "#37687c",
          "image": null
        },
        {
          "id": 864,
          "name": "Biscay Bay",
          "code": "MC_BSAY_S",
          "color": "#1e81a2",
          "image": null
        },
        {
          "id": 867,
          "name": "Dark Blue",
          code: "MC_DRB_S",
          "color": "#203a73",
          "image": null
        },
        {
          "id": 869,
          "name": "Cobalt Blue",
          "code": "MC_CBLU_S",
          "color": "#223567",
          "image": null
        },
        {
          "id": 871,
          "name": "Fuchsia",
          "code": "MC_FCS_S",
          "color": "#d71856",
          "image": null
        },
        {
          "id": 873,
          "name": "Purple",
          "code": "MC_PRL_S",
          "color": "#7a334e",
          "image": null
        },
        {
          "id": 875,
          "name": "Pink",
          "code": "MC_PN_S",
          "color": "#da94a0",
          "image": null
        },
        {
          "id": 877,
          "name": "Bordeaux",
          "code": "MC_BRE_S",
          "color": "#6d3c57",
          "image": null
        },
        {
          "id": 879,
          "name": "Red Metal",
          "code": "MC_RDE_S",
          "color": "#672e33",
          "image": null
        },
        {
          "id": 881,
          "name": "Orange",
          "code": "MC_OAG_S",
          "color": "#c97226",
          "image": null
        },
        {
          "id": 883,
          "name": "Red",
          "code": "MC_RD_S",
          "color": "#b3000c",
          "image": null
        },
        {
          "id": 885,
          "name": "Yellow",
          "code": "MC_YLO_S",
          "color": "#f5c900",
          "image": null
        },
        {
          "id": 887,
          "name": "Pearl White",
          code: "MC_PALT_S",
          "color": "#ececed",
          "image": null
        },
        {
          "id": 889,
          "name": "Black",
          "code": "MC_BAK_S",
          "color": "#000",
          "image": null
        },
        {
          "id": 891,
          "name": "Iridescent Green",
          code: "MC_IDNR_S",
          "color": "#4c5a50",
          "image": null
        },
        {
          "id": 893,
          "name": "Iridescent Blue",
          "code": "MC_IDNL_S",
          "color": "#3f5962",
          "image": null
        },
        {
          "id": 894,
          "name": "Iridescent Pink",
          "code": "MC_IDNI_S",
          "color": "#754b54",
          "image": null,
        },
        {
          "id": 970,
          "name": "Chromo Red",
          "code": null,
          "color": "#721f26",
          "image": null,
          special: true,
        },
        {
          "id": 971,
          "name": "Chromo Blue",
          "code": null,
          "color": "#1e2d91",
          "image": null,
          special: true,
        },
        {
          "id": 899,
          "name": "Graphite Blue",
          "code": "MC_GPBE_S",
          "color": null,
          "image": "https://cdn.emersya.com/cl/34WHTJDM76/p/EOPGV24VGU/CC44D0373X?date=1686664518",
          special: true,
        },
        {
          "id": 901,
          "name": "Graphite Red",
          "code": "MC_GPR_S",
          "color": null,
          "image": "https://cdn.emersya.com/cl/34WHTJDM76/p/EOPGV24VGU/GIGH9TWKM1?date=1686664518",
          special: true,
        },
        {
          "id": 902,
          "name": "Crystal Green",
          "code": "MC_CSRN_S",
          "color": null,
          "image": "https://cdn.emersya.com/cl/34WHTJDM76/p/EOPGV24VGU/EMORR8XKBP?date=1686664518",
          special: true,
        },
        {
          "id": 904,
          "name": "Crystal Black",
          "code": "MC_CSLK_S",
          "color": null,
          "image": "https://cdn.emersya.com/cl/34WHTJDM76/p/EOPGV24VGU/G5GDB1MVSK?date=1686664518",
          special: true,
        },
        {
          "id": 906,
          "name": "Crystal",
          "code": "MC_CYT_S",
          "color": null,
          "image": "https://cdn.emersya.com/cl/34WHTJDM76/p/EOPGV24VGU/UKNUO4MLQW?date=1686664518",
          special: true,
        },
        {
          "id": 909,
          "name": "Red Dot",
          "code": "MC_RDO_S",
          "color": null,
          "image": "https://cdn.emersya.com/cl/34WHTJDM76/p/EOPGV24VGU/NK6JYC9EJK?date=1686664518",
          special: true,
        },
        {
          "id": 911,
          "name": "Green Dot",
          "code": "MC_GEN_S",
          "color": null,
          "image": "https://cdn.emersya.com/cl/34WHTJDM76/p/EOPGV24VGU/UTF4J0JFLV?date=1686664518",
          special: true,
        },
        {
          "id": 913,
          "name": "Green Bottle",
          "code": "MC_GET_S",
          "color": "#113b22",
          "image": null
        }
      ]
    },
    {
      id: 12,
      name: "Stickers",
      label: "STICKERS",
      selectorType: "color",
      camera: camera.stickers,
      configurations: [
        {
          "id": 634,
          "name": "Carbon",
          "code": "MC_CRO_S",
          "color": null,
          "image": "https://cdn.emersya.com/cl/34WHTJDM76/p/HM9E2JX5YE/MMBJPLXUA146?date=1724851133"
        },
        {
          "id": 639,
          "name": "Silver",
          "code": "MC_SLE_S",
          "color": "#a8aaab",
          "image": null
        },
        {
          "id": 641,
          "name": "Dark Grey",
          "code": "MC_DRG_S",
          "color": "#6d6f70",
          "image": null
        },
        {
          "id": 643,
          "name": "Antracite",
          "code": "MC_ATRT_S",
          "color": "#6b6a67",
          "image": null
        },
        {
          "id": 645,
          "name": "Ocher",
          "code": "MC_OHR_S",
          "color": "#89802e",
          "image": null
        },
        {
          "id": 647,
          "name": "Gold",
          "code": "MC_GL_S",
          "color": "#a28135",
          "image": null
        },
        {
          "id": 649,
          "name": "Lime",
          "code": "MC_LM_S",
          "color": "#b1de00",
          "image": null
        },
        {
          "id": 651,
          "name": "Sand",
          "code": "MC_SN_S",
          "color": "#dcce90",
          "image": null
        },
        {
          "id": 653,
          "name": "Ottanium",
          "code": "MC_OTN_S",
          "color": "#37687c",
          "image": null
        },
        {
          "id": 654,
          "name": "Biscay Bay",
          "code": "MC_BSAY_S",
          "color": "#1e81a2",
          "image": null
        },
        {
          "id": 657,
          "name": "Dark Blue",
          code: "MC_DRB_S",
          "color": "#203a73",
          "image": null
        },
        {
          "id": 659,
          "name": "Cobalt Blue",
          "code": "MC_CBLU_S",
          "color": "#223567",
          "image": null
        },
        {
          "id": 661,
          "name": "Fuchsia",
          "code": "MC_FCS_S",
          "color": "#d71856",
          "image": null
        },
        {
          "id": 663,
          "name": "Purple",
          "code": "MC_PRL_S",
          "color": "#7a334e",
          "image": null
        },
        {
          "id": 665,
          "name": "Pink",
          "code": "MC_PN_S",
          "color": "#da94a0",
          "image": null
        },
        {
          "id": 667,
          "name": "Bordeaux",
          "code": "MC_BRE_S",
          "color": "#6d3c57",
          "image": null
        },
        {
          "id": 669,
          "name": "Red Metal",
          "code": "MC_RDE_S",
          "color": "#672e33",
          "image": null
        },
        {
          "id": 671,
          "name": "Orange",
          "code": "MC_OAG_S",
          "color": "#c97226",
          "image": null
        },
        {
          "id": 673,
          "name": "Red",
          "code": "MC_RD_S",
          "color": "#b3000c",
          "image": null
        },
        {
          "id": 675,
          "name": "Yellow",
          "code": "MC_YLO_S",
          "color": "#f5c900",
          "image": null
        },
        {
          "id": 677,
          "name": "Pearl White",
          "code": "MC_PALT_S",
          "color": "#ececed",
          "image": null
        },
        {
          "id": 679,
          "name": "Black",
          "code": "MC_BAK_S",
          "color": "#000",
          "image": null
        },
        {
          "id": 681,
          "name": "Iridescent Green",
          code: "MC_IDNR_S",
          "color": "#4c5a50",
          "image": null
        },
        {
          "id": 683,
          "name": "Iridescent Blue",
          "code": "MC_IDNL_S",
          "color": "#3f5962",
          "image": null
        },
        {
          "id": 684,
          "name": "Iridescent Pink",
          "code": "MC_IDNI_S",
          "color": "#754b54",
          "image": null
        },
        {
          "id": 970,
          "name": "Chromo Red",
          "code": null,
          "color": "#721f26",
          "image": null,
          special: true,
        },
        {
          "id": 971,
          "name": "Chromo Blue",
          "code": null,
          "color": "#1e2d91",
          "image": null,
          special: true,
        },
        {
          "id": 689,
          "name": "Graphite Blue",
          "code": "MC_GPBE_S",
          "color": null,
          "image": "https://cdn.emersya.com/cl/34WHTJDM76/p/EOPGV24VGU/CC44D0373X?date=1686664518",
          special: true,
        },
        {
          "id": 691,
          "name": "Graphite Red",
          "code": "MC_GPR_S",
          "color": null,
          "image": "https://cdn.emersya.com/cl/34WHTJDM76/p/EOPGV24VGU/GIGH9TWKM1?date=1686664518",
          special: true,
        },
        {
          "id": 692,
          "name": "Crystal Green",
          "code": "MC_CSRN_S",
          "color": null,
          "image": "https://cdn.emersya.com/cl/34WHTJDM76/p/EOPGV24VGU/EMORR8XKBP?date=1686664518",
          special: true,
        },
        {
          "id": 694,
          "name": "Crystal Black",
          "code": "MC_CSLK_S",
          "color": null,
          "image": "https://cdn.emersya.com/cl/34WHTJDM76/p/EOPGV24VGU/G5GDB1MVSK?date=1686664518",
          special: true,
        },
        {
          "id": 696,
          "name": "Crystal",
          "code": "MC_CYT_S",
          "color": null,
          "image": "https://cdn.emersya.com/cl/34WHTJDM76/p/EOPGV24VGU/UKNUO4MLQW?date=1686664518",
          special: true,
        },
        {
          "id": 699,
          "name": "Red Dot",
          "code": "MC_RDO_S",
          "color": null,
          "image": "https://cdn.emersya.com/cl/34WHTJDM76/p/EOPGV24VGU/NK6JYC9EJK?date=1686664518",
          special: true,
        },
        {
          "id": 701,
          "name": "Green Dot",
          "code": "MC_GEN_S",
          "color": null,
          "image": "https://cdn.emersya.com/cl/34WHTJDM76/p/EOPGV24VGU/UTF4J0JFLV?date=1686664518",
          special: true,
        },
        {
          "id": 703,
          "name": "Green Bottle",
          "code": "MC_GET_S",
          "color": "#113b22",
          "image": null
        }
      ]
    },
    {
      id: 13,
      name: "Linee",
      label: "LINEE",
      selectorType: "color",
      camera: camera.linee,
      configurations: [
        {
          "id": 705,
          "name": "Carbon",
          "code": "MC_CRO_S",
          "color": null,
          "image": "https://cdn.emersya.com/cl/34WHTJDM76/p/HM9E2JX5YE/MMBJPLXUA146?date=1724851133"
        },
        {
          "id": 709,
          "name": "Silver",
          "code": "MC_SLE_S",
          "color": "#a8aaab",
          "image": null
        },
        {
          "id": 711,
          "name": "Dark Grey",
          "code": "MC_DRG_S",
          "color": "#6d6f70",
          "image": null
        },
        {
          "id": 713,
          "name": "Antracite",
          "code": "MC_ATRT_S",
          "color": "#6b6a67",
          "image": null
        },
        {
          "id": 715,
          "name": "Ocher",
          "code": "MC_OHR_S",
          "color": "#89802e",
          "image": null
        },
        {
          "id": 717,
          "name": "Gold",
          "code": "MC_GL_S",
          "color": "#a28135",
          "image": null
        },
        {
          "id": 719,
          "name": "Lime",
          "code": "MC_LM_S",
          "color": "#b1de00",
          "image": null
        },
        {
          "id": 721,
          "name": "Sand",
          "code": "MC_SN_S",
          "color": "#dcce90",
          "image": null
        },
        {
          "id": 723,
          "name": "Ottanium",
          "code": "MC_OTN_S",
          "color": "#37687c",
          "image": null
        },
        {
          "id": 724,
          "name": "Biscay Bay",
          "code": "MC_BSAY_S",
          "color": "#1e81a2",
          "image": null
        },
        {
          "id": 727,
          "name": "Dark Blue",
          code: "MC_DRB_S",
          "color": "#203a73",
          "image": null
        },
        {
          "id": 729,
          "name": "Cobalt Blue",
          "code": "MC_CBLU_S",
          "color": "#223567",
          "image": null
        },
        {
          "id": 731,
          "name": "Fuchsia",
          "code": "MC_FCS_S",
          "color": "#d71856",
          "image": null
        },
        {
          "id": 733,
          "name": "Purple",
          "code": "MC_PRL_S",
          "color": "#7a334e",
          "image": null
        },
        {
          "id": 735,
          "name": "Pink",
          "code": "MC_PN_S",
          "color": "#da94a0",
          "image": null
        },
        {
          "id": 737,
          "name": "Bordeaux",
          "code": "MC_BRE_S",
          "color": "#6d3c57",
          "image": null
        },
        {
          "id": 739,
          "name": "Red Metal",
          "code": "MC_RDE_S",
          "color": "#672e33",
          "image": null
        },
        {
          "id": 741,
          "name": "Orange",
          "code": "MC_OAG_S",
          "color": "#c97226",
          "image": null
        },
        {
          "id": 743,
          "name": "Red",
          "code": "MC_RD_S",
          "color": "#b3000c",
          "image": null
        },
        {
          "id": 745,
          "name": "Yellow",
          "code": "MC_YLO_S",
          "color": "#f5c900",
          "image": null
        },
        {
          "id": 747,
          "name": "Pearl White",
          code: "MC_PALT_S",
          "color": "#ececed",
          "image": null
        },
        {
          "id": 749,
          "name": "Black",
          "code": "MC_BAK_S",
          "color": "#000",
          "image": null
        },
        {
          "id": 751,
          "name": "Iridescent Green",
          code: "MC_IDNR_S",
          "color": "#4c5a50",
          "image": null
        },
        {
          "id": 753,
          "name": "Iridescent Blue",
          "code": "MC_IDNL_S",
          "color": "#3f5962",
          "image": null
        },
        {
          "id": 754,
          "name": "Iridescent Pink",
          "code": "MC_IDNI_S",
          "color": "#754b54",
          "image": null
        },
        {
          "id": 970,
          "name": "Chromo Red",
          "code": null,
          "color": "#721f26",
          "image": null,
          special: true,
        },
        {
          "id": 971,
          "name": "Chromo Blue",
          "code": null,
          "color": "#1e2d91",
          "image": null,
          special: true,
        },
        {
          "id": 759,
          "name": "Graphite Blue",
          "code": "MC_GPBE_S",
          "color": null,
          "image": "https://cdn.emersya.com/cl/34WHTJDM76/p/EOPGV24VGU/CC44D0373X?date=1686664518",
          special: true,
        },
        {
          "id": 761,
          "name": "Graphite Red",
          "code": "MC_GPR_S",
          "color": null,
          "image": "https://cdn.emersya.com/cl/34WHTJDM76/p/EOPGV24VGU/GIGH9TWKM1?date=1686664518",
          special: true,
        },
        {
          "id": 762,
          "name": "Crystal Green",
          "code": "MC_CSRN_S",
          "color": null,
          "image": "https://cdn.emersya.com/cl/34WHTJDM76/p/EOPGV24VGU/EMORR8XKBP?date=1686664518",
          special: true,
        },
        {
          "id": 764,
          "name": "Crystal Black",
          "code": "MC_CSLK_S",
          "color": null,
          "image": "https://cdn.emersya.com/cl/34WHTJDM76/p/EOPGV24VGU/G5GDB1MVSK?date=1686664518",
          special: true,
        },
        {
          "id": 766,
          "name": "Crystal",
          "code": "MC_CYT_S",
          "color": null,
          "image": "https://cdn.emersya.com/cl/34WHTJDM76/p/EOPGV24VGU/UKNUO4MLQW?date=1686664518",
          special: true,
        },
        {
          "id": 769,
          "name": "Red Dot",
          "code": "MC_RDO_S",
          "color": null,
          "image": "https://cdn.emersya.com/cl/34WHTJDM76/p/EOPGV24VGU/NK6JYC9EJK?date=1686664518",
          special: true,
        },
        {
          "id": 771,
          "name": "Green Dot",
          "code": "MC_GEN_S",
          "color": null,
          "image": "https://cdn.emersya.com/cl/34WHTJDM76/p/EOPGV24VGU/UTF4J0JFLV?date=1686664518",
          special: true,
        },
        {
          "id": 773,
          "name": "Green Bottle",
          "code": "MC_GET_S",
          "color": "#113b22",
          "image": null
        }
      ]
    },
    {
      id: 14,
      name: "Loghi",
      label: "LOGHI",
      selectorType: "color",
      camera: camera.loghi,
      configurations: [
        {
          id: 774,
          name: "Carbon",
          code: "MC_CRO_S",
          color: null,
          image: "https://cdn.emersya.com/cl/34WHTJDM76/p/HM9E2JX5YE/MMBJPLXUA146?date=1724851133"
        },
        {
          id: 779,
          name: "Silver",
          code: "MC_SLE_S",
          color: "#a8aaab",
          image: null
        },
        {
          id: 781,
          name: "Dark Grey",
          code: "MC_DRG_S",
          color: "#6d6f70",
          image: null
        },
        {
          id: 783,
          name: "Antracite",
          code: "MC_ATRT_S",
          color: "#6b6a67",
          image: null
        },
        {
          id: 785,
          name: "Ocher",
          code: "MC_OHR_S",
          color: "#89802e",
          image: null
        },
        {
          id: 787,
          name: "Gold",
          code: "MC_GL_S",
          color: "#a28135",
          image: null
        },
        {
          id: 789,
          name: "Lime",
          code: "MC_LM_S",
          color: "#b1de00",
          image: null
        },
        {
          id: 791,
          name: "Sand",
          code: "MC_SN_S",
          color: "#dcce90",
          image: null
        },
        {
          id: 793,
          name: "Ottanium",
          code: "MC_OTN_S",
          color: "#37687c",
          image: null
        },
        {
          id: 794,
          name: "Biscay Bay",
          code: "MC_BSAY_S",
          color: "#1e81a2",
          image: null
        },
        {
          id: 797,
          name: "Dark Blue",
          code: "MC_DRB_S",
          color: "#203a73",
          image: null
        },
        {
          id: 799,
          name: "Cobalt Blue",
          code: "MC_CBLU_S",
          color: "#223567",
          image: null
        },
        {
          id: 801,
          name: "Fuchsia",
          code: "MC_FCS_S",
          color: "#d71856",
          image: null
        },
        {
          id: 803,
          name: "Purple",
          code: "MC_PRL_S",
          color: "#7a334e",
          image: null
        },
        {
          id: 805,
          name: "Pink",
          code: "MC_PN_S",
          color: "#da94a0",
          image: null
        },
        {
          id: 807,
          name: "Bordeaux",
          code: "MC_BRE_S",
          color: "#6d3c57",
          image: null
        },
        {
          id: 809,
          name: "Red Metal",
          code: "MC_RDE_S",
          color: "#672e33",
          image: null
        },
        {
          id: 811,
          name: "Orange",
          code: "MC_OAG_S",
          color: "#c97226",
          image: null
        },
        {
          id: 813,
          name: "Red",
          code: "MC_RD_S",
          color: "#b3000c",
          image: null
        },
        {
          id: 815,
          name: "Yellow",
          code: "MC_YLO_S",
          color: "#f5c900",
          image: null
        },
        {
          id: 817,
          name: "Pearl White",
          code: "MC_PALT_S",
          color: "#ececed",
          image: null
        },
        {
          id: 819,
          name: "Black",
          code: "MC_BAK_S",
          color: "#000",
          image: null
        },
        {
          id: 821,
          name: "Iridescent Green",
          code: "MC_IDNR_S",
          color: "#4c5a50",
          image: null
        },
        {
          id: 823,
          name: "Iridescent Blue",
          code: "MC_IDNL_S",
          color: "#3f5962",
          image: null
        },
        {
          id: 843,
          name: "Green Bottle",
          code: "MC_GET_S",
          color: "#113b22",
          image: null
        }
      ]
    },
    {
      id: 'text-image',
      name: "Testo-immagine",
      label: "Testo/Firma",
      selectorType: "text",
      camera: camera.text,
    }
  ]
}
