import Header from "../organisms/Header";
import styles from "./MainTemplate.module.css";
import useAppStore from "../hooks/useAppStore";
import Modal from "../organisms/Modal";
import MenuContainer from "../organisms/MenuContainer";
import Footer from "../organisms/Footer";
import ProductInfo from "../organisms/ProductInfo";
import {useTranslation} from "react-i18next";
import useDynamicMaterials from "../hooks/useDynamicMaterials";
import SaddleModal from "../organisms/SaddleModal";
import defaultSaddleConfiguration from "../configurations/defaultSaddleConfiguration";

export default function MainTemplate({
  children,
  ...props
}) {

  const { t } = useTranslation();

  const {
    menuOpened, resetModalOpened, toggleResetModal,
    price, showARModal, currentGroup,
    designModalOpened, toggleDesignModal,
    saddleModalOpened, toggleSaddleModal,
    colorWarningModalOpened, toggleColorWarningModal,
    toggleShowRecap,
  } = useAppStore( state => ({
    menuOpened: state.menuOpened,
    resetModalOpened: state.resetModalOpened,
    toggleResetModal: state.toggleResetModal,
    designModalOpened: state.designModalOpened,
    toggleDesignModal: state.toggleDesignModal,
    price: state.price,
    showARModal: state.showARModal,
    toggleShowARModal: state.toggleShowARModal,
    currentGroup: state.currentGroup,
    toggleShowRecap: state.toggleShowRecap,
    toggleSaddleModal: state.toggleSaddleModal,
    saddleModalOpened: state.saddleModalOpened,
    colorWarningModalOpened: state.colorWarningModalOpened,
    toggleColorWarningModal: state.toggleColorWarningModal,
  }));

  const {
    modelConfiguration, setCurrentMaterials, setCurrentGroup,
    designSelected, setDesignSelected, updateSaddleConfiguration
  } = useAppStore( (state) => ({
    modelConfiguration: state.modelConfiguration,
    setCurrentMaterials: state.setCurrentMaterials,
    setCurrentGroup: state.setCurrentGroup,
    designSelected: state.designSelected,
    setDesignSelected: state.setDesignSelected,
    updateSaddleConfiguration: state.updateSaddleConfiguration,
  }));

  useDynamicMaterials();

  let menuContainerClassName = `${styles.menuContainer} ${menuOpened ? styles.menuContainerOpened : null}`;
  menuContainerClassName = showARModal ? `${menuContainerClassName} ${styles.hide}` : `${menuContainerClassName}`;

  const headerClassName = showARModal ? `${styles.header} ${styles.hide}` : `${styles.header}`;
  const footerClassName = showARModal ? `${styles.footer} ${styles.hide}` : `${styles.footer}`;

  const confirmReset = () => {
    setCurrentMaterials([]);
    // remove saddle images and text customizations
    applySaddleConfiguration(defaultSaddleConfiguration);
    setCurrentGroup(modelConfiguration.configurableGroups[0]);
    toggleResetModal();
  };

  const confirmDesignReset = () => {
    setCurrentMaterials([]);
    setTimeout(function() {
      setCurrentMaterials([{
        group: currentGroup,
        configuration: designSelected,
      }]);
    }, 200);

    //setDesignSelected(null);
    toggleDesignModal();
  };

  const applySaddleConfiguration = (configuration) => {
    updateSaddleConfiguration(configuration);
  };

  return (
    <div className={styles.main}>

      <div className={headerClassName}>
        <Header />
      </div>

      <div className={styles.contents}>
        {children}

        <ProductInfo name={modelConfiguration.name} price={price} />

        <div className={menuContainerClassName}>
          <MenuContainer />
        </div>

      </div>

      <div className={footerClassName}>
        <Footer />
      </div>

      <Modal
        isOpen={resetModalOpened}
        message={t("text.reset")}
        title={t("text.notice")}
        onCancel={toggleResetModal}
        onConfirm={confirmReset}
        onClose={toggleResetModal}
      />

      <Modal
        isOpen={designModalOpened}
        title={t("text.notice")}
        message={t("text.design-reset")}
        onCancel={toggleDesignModal}
        onConfirm={confirmDesignReset}
        onClose={toggleDesignModal}
      />

      <Modal
        isOpen={colorWarningModalOpened}
        title={t("text.notice")}
        message={t("text.color-warning")}
        onClose={() => {
          toggleColorWarningModal();
          toggleShowRecap();
        }}
      />

      <SaddleModal
        isOpen={saddleModalOpened}
        onClose={toggleSaddleModal}
        onConfirm={applySaddleConfiguration}
      />

    </div>
  );
}
