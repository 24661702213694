import React from 'react';
import Label from "../atoms/Label";
import Button from "../atoms/Button";
import styles from "./TextIconButton.module.css";
import ThemeIcon from "../icons/ThemeIcon";
import BackIcon from "../icons/BackIcon";
import VirtualTourIcon from "../icons/VirtualTourIcon";
import ResetIcon from "../icons/ResetIcon";
import CloseIcon from "../icons/CloseIcon";
import Inline from "../atoms/Inline";
import HamburgerIcon from "../icons/HamburgerIcon";

export default function TextIconButton({
  text,
  icon,
  onClick,
  disabled= false,
  alignIcon = 'left',
  color,
  ...props
}) {

  return (
    <Button onClick={onClick} color={color} transparent={true} className={styles.textIcon} disabled={disabled} {...props} >
      <Inline reverse={alignIcon !== 'left'}>
        {renderIcon(icon)}
        <Label>
          {text}
        </Label>
      </Inline>
    </Button>
  );
};

function renderIcon(icon) {
  switch (icon) {
    case "back":
      return <BackIcon/>;

    case "theme":
      return <ThemeIcon/>;

    case "virtualTour":
      return <VirtualTourIcon/>;

    case "reset":
      return <ResetIcon/>;

    case "close":
      return <CloseIcon/>;

    case "hamburger":
      return <HamburgerIcon />;

    default:
      return null;
  }

}
