.primary {
  composes: button from '../style.module.css';
  background-color: var(--button-primary-background-color);
  color: var(--button-primary-text-color);
}

.secondary {
  composes: button from '../style.module.css';
  background-color: var(--button-secondary-background-color);
  color: var(--button-secondary-text-color);
}

.purchase {
  composes: button from '../style.module.css';
  background-color: var(--button-purchase-background--color);
  color: var(--button-purchase-text-color);
  border: 1px solid transparent;
}

.customize {
  composes: secondary;
}

.customize:enabled span:hover {
  opacity: unset;
}

.customize:hover {
  background-color: var(--button-secondary-text-color);
  color: var(--button-secondary-background-color);
}