.container {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  transition: all 200ms ease-in;
}

.containerOpened {
  z-index: 99;
  transition: all 200ms ease-in;
}

.overlay {
  position: absolute;
  background: var(--main-container-background-color);
  opacity: 0;
  inset: 0;
  transition: all 100ms ease-in;
}

.overlayOpened {
  opacity: .6;
}

.modal {
  position: absolute;
  opacity: 0;
  scale: 0;
  width: 20rem;
  height: 14rem;
  border: 1px solid #979797;
  background-color: var(--menu-footer-background-color);
  padding: 0 0 1rem 0;
  transition: all 200ms ease-in;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.modalContent {
  width: 16rem;
  height: 10rem;
}

.modalOpened {
  opacity: 1;
  scale: 1;
}

.title {
  color: #e53238;
  composes: black spacing-medium from "../style.module.css";
  text-align: center;
  font-size: .8165rem;
  text-transform: uppercase;
}

.message {
  composes: size-normal from "../style.module.css";
  line-height: 1.2rem;
  text-align: center;
  color: var(--main-container-foreground-color);
  text-transform: uppercase;
  overflow-y: auto;
  white-space: pre-line;
}

.buttonClose {
position: absolute;
display: flex;
align-items: center;
justify-content: center;
right: 0.6rem;
top: 0.6rem;
}

.buttonClose:hover {
transform: scale(1.1);
cursor: pointer;
}


/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {

  .modal {
    position: relative;
    align-items: center;
    width: 47rem;
    height: 22rem;
    padding: 2rem 0;
  }

  .modalContent {
    width: 29rem;
    height: 13.3rem;
    padding: 0 1.81rem;
  }

  .message {
    line-height: 1.5rem;
  }

}
