input[type='radio'] {
  display: none;
}

input[type='radio']:checked + div:after {
  opacity: 1;
}

.customRadio {
  cursor: pointer;
  width: 1.43rem;
  height: 1.43rem;
  border: 1px solid var(--button-primary-background-color);
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customRadio::after {
  content: '';
  width: .4rem;
  height: .4rem;
  background: var(--button-primary-background-color);
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.2s;
}

.text {
  cursor: pointer;
  composes: size-small from '../style.module.css';
  composes: spacing-normal from '../style.module.css';
  composes: bold from '../style.module.css';
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {

  .text {
    letter-spacing: .203rem;
    font-size: .875rem;
  }

}
