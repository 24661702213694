import styles from "./SaddleModal.module.css"
import Inline from "../atoms/Inline";
import Button from "../atoms/Button";
import CloseIcon from "../icons/CloseIcon";
import {useTranslation} from "react-i18next";
import SaddleSelector from "./SaddleSelector";
import {useContext, useEffect, useState} from "react";
import EmersyaViewerContext from "../contexts/EmersyaViewerContext";
import useAppStore from "../hooks/useAppStore";
import defaultSaddleConfiguration
  from "../configurations/defaultSaddleConfiguration";

export default function SaddleModal({
  isOpen,
  onConfirm,
  onClose,
  ...props
}){

  const {viewer, loaded, progress} = useContext(EmersyaViewerContext);

  const { saddleConfiguration, modelConfiguration, currentGroup } = useAppStore( state => ({
    saddleConfiguration: state.saddleConfiguration,
    modelConfiguration: state.modelConfiguration,
    currentGroup: state.currentGroup
  }));

  const [side, setSide] = useState('right');

  useEffect(function(){
    if(viewer === null) return;
    if(loaded === false) return;

    if(isOpen === true) {
      setSide('right');

      const object3d = document.getElementById('cipollini3dConfigurator');

      if(object3d === null) return;

      const zoomed3d = document.getElementById('zoomed3d');
      zoomed3d.appendChild(object3d.firstElementChild);

      window.dispatchEvent(new Event('resize'));

      viewer.setCamera({
        camera: modelConfiguration.saddleCamera.right
      });

      viewer.lockCamera().then( res => console.log(res, 'locked camera'));
    }

  }, [viewer, loaded, isOpen]);

  const close = () => {
    onClose();

    const object3d = document.getElementById('cipollini3dConfigurator');

    if(object3d === null) return;

    const zoomed3d = document.getElementById('zoomed3d');
    object3d.appendChild(zoomed3d.firstElementChild);

    window.dispatchEvent(new Event('resize'));

    viewer.setCamera({
      camera:  currentGroup.camera
    });

    viewer.unlockCamera().then( res => console.log('unlocked camera', res));
  }

  const skip = (event) => {
    //reset chosen configuration
    onConfirm(defaultSaddleConfiguration);
  };

  const { t } = useTranslation();

  const containerClassName = `${styles.container} ${isOpen ? styles.containerOpened : null}`;
  const overlayClassName = `${styles.overlay} ${isOpen ? styles.overlayOpened : null}`;
  const modalClassName = `${styles.modal} ${isOpen ? styles.modalOpened : null}`;

  const rightValid = (
    saddleConfiguration.right.text !== '' &&
    saddleConfiguration.right.textColor !== null
  ) || (
    saddleConfiguration.right.imageFile.length > 0
  ) || (
    saddleConfiguration.right.signatureColor !== null
  );

  const leftValid = (
    saddleConfiguration.left.text !== '' &&
    saddleConfiguration.left.textColor !== null
  ) || (
    saddleConfiguration.left.imageFile.length > 0
  );

  const isValid = rightValid || leftValid;

  return (
      <div className={containerClassName}>
        <div className={overlayClassName}/>
        <div className={modalClassName}>
          <button className={styles.buttonClose} onClick={close}>
            <CloseIcon/>
          </button>
          <div className={styles.modalContent}>
            <SaddleSelector
              side={side}
              onSideSelect={setSide}
            />
            <div style={{padding: '1rem 0'}}>
              <Inline align="center" gap="large">
                <Button primary={false} onClick={skip}>{t('button.skipstep')}</Button>
                <Button
                  disabled={! isValid}
                  onClick={close}>{t('button.apply')}</Button>
              </Inline>
            </div>
          </div>
        </div>
      </div>
    );
}
