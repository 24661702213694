.main {
  position: relative;
  height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.contents {
  height: 100%;
}

.header {
  composes: w100 from "../style.module.css";
  height: 3.57rem;
  position: relative;
  z-index: 6;
  background-color: var(--main-container-background-color);
  transition: all 200ms ease-in-out;
  opacity: 1;
}

.menuContainer {
  position: absolute;
  top: 3.57rem; /* header height */
  left: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  background-color: var(--menu-footer-background-color);
  transition: all 200ms ease-in-out;
  z-index: 4;
  opacity: 1;
}

.menuContainerOpened {
  left: 0;
  z-index: 4;
}

.variantSelector {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  height: 5.06em;
  padding: 0;
}

.footer {
  composes: w100 from "../style.module.css";
  transition: all 200ms ease-in-out;
  opacity: 1;
  /*position: absolute;
  bottom: 0;*/
}

.purchaseFooter {
  height: 12rem;
}

.footerButtonContainer {
  composes: w100 from "../style.module.css";
  padding: .3125rem 1rem .875rem 1rem;
}

.productInfo {
  composes: w100 from "../style.module.css";
  position: absolute;
  top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 200ms ease-in-out;
}

.productInfoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5rem;
}

button {
  cursor: pointer;
}

.loading {
  position: absolute;
  left: 100%;
  top: 100%;
}

.ready {
  position: static;
  transform: translate(0,0);
}

.hide {
  opacity: 0;
  z-index: -1;
}

.loader {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  margin:15px auto;
  position: relative;
  color: #e53238;
  box-sizing: border-box;
  animation: animloader 2s linear infinite;
  transform: scale(1.5);
}

@keyframes animloader {
  0% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
  }
  25% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 2px;
  }
  50% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 2px,  -38px 0 0 -2px;
  }
  75% {
    box-shadow: 14px 0 0 2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
  }
  100% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
  }
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {

  .viewerContainer {
    width: 100%;
    height: 100%;
  }

  .menuContainer {
    top: 0;
    bottom: 0;
    left: unset;
    right: 0;
    width: 21.06rem;
    transition: none;
    background-color: transparent;
  }

  .variantSelector {
    padding: 1rem 0 0 0;
    z-index: 4;
  }

  .menuFooter {
    display: none;
  }

  .productInfo {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    left: 3.125rem;
  }

  .productInfoContainer {
    align-items: flex-start;
  }

  .buttonContainer:hover {
    cursor: pointer;
    transform: scale(1.1);;
  }

  .backLabel {
    display: block;
  }

  .resetLabel {
    display: block;
  }


  .footerButtonBack {
    visibility: hidden;
  }

}
