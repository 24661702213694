export const createConfiguratorSlice = (set) => ({
  currentMaterials: [],
  setCurrentMaterials: (items) => set({currentMaterials: items}),
  setConfigurableGroups: (items) => set((state) => ({
    ...state,
    modelConfiguration: {
      ...state.modelConfiguration,
      configurableGroups: items
    }
  }))
});
