import useAppStore from "./useAppStore";
import models from "../configurations/models";
import {useEffect} from "react";
import * as utils from "../utils/materials";

const designGroupsName = {
  'monocromo': ['Design', 'Paint', 'Telaio', 'Loghi', 'Testo-immagine'],
  'multicolor': ['Design', 'Paint', 'Telaio', 'Stickers', 'Livree', 'Linee', 'Loghi', 'Testo-immagine'],
  'spectaflair': ['Design', 'Loghi', 'Testo-immagine'],
}

export default function useDynamicMaterials() {

  const {currentGroup, setConfigurableGroups, modelConfiguration, modelId, currentMaterials} = useAppStore( (state) => ({
    modelConfiguration: state.modelConfiguration,
    setConfigurableGroups: state.setConfigurableGroups,
    currentGroup: state.currentGroup,
    currentMaterials: state.currentMaterials,
    modelId: state.modelId,
  }));

  const designGroup = utils.getMaterialByGroupId(currentMaterials, 'design');

  useEffect(function () {
    if(currentGroup === undefined) return;
    if(currentGroup === null) return;
    if(currentGroup.id !== 'design') return;

    if(designGroup !== undefined) {

      const design = designGroup.configuration.id;

      if(designGroupsName[design] === undefined) {
        console.error('invalid design ' + design);
        return;
      }

      const configurableGroups = models[modelId].configurableGroups
        .filter( configurableGroup => designGroupsName[design].indexOf(configurableGroup.name) >= 0);

      setConfigurableGroups(configurableGroups);
    }

  }, [currentGroup, designGroup]);

}
