export default {
  id: 'paint',
  name: "Paint",
  label: "Paint",
  selectorType: "color",
  camera: null,
  configurations: [{
    id: 'shiny',
    name: "Shiny",
    code: null,
    color: "#FF7500",
    image: "https://d3kaxrfv1gts8b.cloudfront.net/LUCIDO.jpg"
  },{
    id: 'matt',
    name: "Matt",
    code: null,
    color: "#FFA75D",
    image: "https://d3kaxrfv1gts8b.cloudfront.net/OPACA.jpg"
  }]
}
