import {useRef} from "react";
import storeCreator from "./stores/storeCreator";
import StoreContext from "./contexts/StoreContext";
import models from "./configurations/models";
import Label from "./atoms/Label";

export default function StoreProvider({
  options,
  children,
  ...props
}) {


  if (models[options.modelId] === undefined ) {
    return (
      <>
        <Label weight="normal" size="medium">modelId </Label>
        <Label size="medium">{options.modelId} </Label>
        <Label weight="normal" size="medium">not available</Label>
      </>
    );
  }

  const storeRef = useRef();

  if (!storeRef.current) {
    storeRef.current = storeCreator({
      ...options,
      ...props
    });
  }

  return (
    <StoreContext.Provider value={storeRef.current}>
      {children}
    </StoreContext.Provider>
  );

}
