.container {
  width: 100%;
  background-color: var(--progress-container-background-color);
  height: 1px;
}

.progress{
  background-color:  var(--progress-color);
  height: 100%;
  transition: width 400ms ease-in;
}

.loading {
  composes: progress;
  background-color: var(--loading-color);
}

