import * as utils from "./materials";
import bikes from "../configurations/bikes";

function getLogoColorByGroupName(model, currentMaterials, groupName) {
  const logoColor = utils.getMaterialByGroupName(currentMaterials, 'Loghi');

  if(logoColor === undefined) return [];

  const disabled = model.configurableGroups.find( group => group.name === groupName).configurations
      .filter( color => color.name === logoColor.configuration.name );

  return disabled;
}

export default function(model, currentMaterials, currentGroup) {

  const designColor = utils.getMaterialByGroupId(currentMaterials, 'design');

  if(
    currentGroup.id === 'design' ||
    currentGroup.id === 'paint' ||
    designColor.configuration.id === 'spectaflair'
  ) return [];


  const mainColor = utils.getMaterialByGroupName(currentMaterials, 'Telaio');
  const secondLevelColor = utils.getMaterialByGroupName(currentMaterials, 'Stickers');
  const thirdLevelColor = utils.getMaterialByGroupName(currentMaterials, 'Linee');

  const specialColor = [mainColor, secondLevelColor, thirdLevelColor].find( color => color && color.configuration.special);

  let disabled = [];

  // main and logo colors can't be the same
  // second level and logo colors can't be the same
  // third level and logo colors can't be the same
  // this rule apply to all bike models
  if(
    currentGroup.name === 'Telaio' ||
    currentGroup.name === 'Stickers' ||
    currentGroup.name === 'Linee'
  ) {
    disabled = getLogoColorByGroupName(model, currentMaterials, currentGroup.name);
  }


  // common rules, for all models
  if(specialColor && (
      currentGroup.name === 'Telaio' ||
      currentGroup.name === 'Stickers'
    )
  ) {
    disabled = disabled.concat(model.configurableGroups
      .find( group => group.name === currentGroup.name).configurations
      .filter( color => color.special && color.name !== specialColor.configuration.name )
    );
  }

  // AD.ONE - only one special color can be used for all chassis parts (main, second, third)
  if(
    specialColor &&
    model.name === bikes.adone &&
    currentGroup.name === 'Linee'
  ) {
    disabled = disabled.concat(model.configurableGroups
      .find( group => group.name === currentGroup.name).configurations
      .filter( color => color.special && color.name !== specialColor.configuration.name )
    );
  }


  // FLUSSO, DOLOMIA, THE ONE - only one special color can be used, but only for main and second level)
  if(
    specialColor &&
    currentGroup.name === 'Linee' &&
    (
      model.name === bikes.flusso ||
      model.name === bikes.theone ||
      model.name === bikes.dolomia
    )
  ) {
    disabled = disabled.concat(model.configurableGroups
      .find( group => group.name === currentGroup.name).configurations
      .filter( color => color.special)
    );
  }
  
  // FLUSSO, DOLOMIA, remove PEARL WHITE for monocromo, multicolor (Linee)
  if(
    (model.name === bikes.flusso || model.name === bikes.dolomia) &&
    designColor.configuration.id === 'monocromo'
  ) {
    disabled = disabled.concat(model.configurableGroups
      .find( group => group.name === currentGroup.name).configurations
      .filter( color => color.name === 'Pearl White'))
  }
  
  if(currentGroup.name === 'Loghi') {
    //available loghi colors
    disabled = model.configurableGroups.find( group => group.name === 'Loghi').configurations
      .filter( color =>
        color.name === mainColor.configuration.name ||
        (
          (
            model.name === bikes.adone ||
            model.name === bikes.flusso ||
            model.name === bikes.theone
          ) && secondLevelColor && color.name === secondLevelColor.configuration.name
        ) || (
          (
            model.name === bikes.adone ||
            model.name === bikes.dolomia
          ) && thirdLevelColor && color.name === thirdLevelColor.configuration.name
        )
      );
  }

  return disabled;
}
