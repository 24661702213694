import defaultSaddleConfiguration from "../configurations/defaultSaddleConfiguration";

export const createApplicationSlice = (set) => ({
  theme: 'dark',
  menuOpened: false,
  resetModalOpened: false,
  designModalOpened: false,
  saddleModalOpened: false,
  colorWarningModalOpened: false,
  showRecap: false,
  showARModal: false,

  saddleConfiguration: defaultSaddleConfiguration,
  updateSaddleConfiguration: (value) => set(state => ({
    saddleConfiguration: {
      right: { ...state.saddleConfiguration.right },
      left: { ...state.saddleConfiguration.left },
      ...value
    }})
  ),

  toggleMenu: () => set(state => ({
    menuOpened: !state.menuOpened
  })),
  toggleTheme: () => set(state => ({
    theme: state.theme === 'dark' ? 'light' : 'dark'
  })),
  toggleResetModal: () => set(state => ({
    resetModalOpened: !state.resetModalOpened
  })),
  toggleDesignModal: () => set(state => ({
    designModalOpened: !state.designModalOpened
  })),
  toggleSaddleModal: () => set(state => ({
    saddleModalOpened: !state.saddleModalOpened
  })),
  toggleColorWarningModal: () => set(state => ({
    colorWarningModalOpened: !state.colorWarningModalOpened
  })),



  applyingMaterials: false,
  toggleApplyingMaterials: () => set(state => ({
    applyingMaterials: !state.applyingMaterials
  })),

  toggleShowRecap: () => set(state => ({
    showRecap: !state.showRecap
  })),

  toggleShowARModal: () => set(state => ({
    showARModal: !state.showARModal
  })),
  currentGroup: null,
  setCurrentGroup: (group) => set({ currentGroup: group}),
  designSelected: null,
  setDesignSelected: (item) => set({designSelected: item}),
});
