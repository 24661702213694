import useAppStore from "../hooks/useAppStore";
import ColorSelector from "../molecules/ColorSelector";
import {useTranslation} from "react-i18next";
import Button from "../atoms/Button";
import * as utils from "../utils/materials";
import getDisabledColors from "../utils/getDisabledColors";
import models from "../configurations/models";

export default function VariantSelector({
  ...props
}) {
  const { t } = useTranslation();

  const {
    modelConfiguration, currentGroup, currentMaterials,
    setCurrentMaterials, applyingMaterials, toggleDesignModal,
    setDesignSelected,toggleSaddleModal, setCurrentGroup, modelId,
  } = useAppStore( (state) => ({
    modelConfiguration: state.modelConfiguration,
    currentGroup: state.currentGroup,
    setCurrentGroup: state.setCurrentGroup,
    setCurrentMaterials: state.setCurrentMaterials,
    currentMaterials: state.currentMaterials,
    applyingMaterials: state.applyingMaterials,
    toggleDesignModal: state.toggleDesignModal,
    setDesignSelected: state.setDesignSelected,
    toggleSaddleModal: state.toggleSaddleModal,
    modelId: state.modelId
  }));

  if(currentGroup === null) return null;

  const material = modelConfiguration.configurableGroups.find( group => group.id === currentGroup.id);
  const currentMaterial = utils.getMaterialByGroupId(currentMaterials, currentGroup.id);
  const configuration =  currentMaterial !== undefined ? currentMaterial.configuration : null;
  const selected = configuration && material.configurations.find( item => item.id === configuration.id);

  const selection = (selectedMaterial) => {
    return currentMaterials
      .filter( material => material.group.id !== selectedMaterial.group.id)
      .concat(selectedMaterial);
  };

  const onSelect = (item) => {
    //Open Reset Design modal
    if(
      currentGroup.id === 'design' &&
      configuration !== null  &&
      item.name !== configuration.name) {
      toggleDesignModal();
      setDesignSelected(item);
      return;
    }
    
    setCurrentMaterials(
      selection({
        group: currentGroup,
        configuration: item,
      })
    );
  };
  
  switch (currentGroup.selectorType) {

    case 'color':
      const disabledItems = getDisabledColors(modelConfiguration, currentMaterials, currentGroup);

      return (
        <ColorSelector
          items={material.configurations}
          onSelect={onSelect}
          selected={selected}
          disabled={applyingMaterials}
          disableSpecialColor={false}
          disabledItems={disabledItems}
          size={currentGroup && (currentGroup.id === 'design' || currentGroup.id === 'paint') ? 'large' : 'medium'}
        />
      );

    case 'text':
      return (
        <Button primary={false} customize={true} onClick={toggleSaddleModal}>PERSONALIZZA SOTTOSELLA</Button>
      );

    default:
      console.error(`unknown group ${currentGroup}`);
      return null;
  }

}
