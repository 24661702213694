import MaterialsMenu from "./MaterialsMenu";
import Stack from "../atoms/Stack";
import BackIcon from "../icons/BackIcon";
import Label from "../atoms/Label";
import VirtualTourIcon from "../icons/VirtualTourIcon";
import styles from "../organisms/MenuContainer.module.css";
import ThemeIcon from "../icons/ThemeIcon";
import useAppStore from "../hooks/useAppStore";
import {useContext} from "react";
import EmersyaViewerContext from "../contexts/EmersyaViewerContext";
import {useTranslation} from "react-i18next";

export default function MenuContainer({
}) {

  const { t } = useTranslation();

  const {showRecap, menuOpened, modelConfiguration,setCurrentGroup, toggleTheme, toggleShowRecap, toggleMenu} = useAppStore( (state) => ({
    showRecap: state.showRecap,
    menuOpened: state.menuOpened,
    modelConfiguration: state.modelConfiguration,
    setCurrentGroup: state.setCurrentGroup,
    toggleShowRecap: state.toggleShowRecap,
    toggleTheme: state.toggleTheme,
    toggleMenu: state.toggleMenu,
  }));

  const emersyaViewerContext = useContext(EmersyaViewerContext);
  const viewer = emersyaViewerContext.viewer;

  const menuClassName = `${styles.menu} ${menuOpened ? styles.scrollable : null}`;
  const menuFooterClassName = `${styles.menuFooter} ${menuOpened ? styles.menuFooterOpened : null}`;

  return (
    <>
      {/*{showRecap === false && ( */}
        <div className={menuClassName}>
          <MaterialsMenu
            items={modelConfiguration.configurableGroups}
            onSelect={(item) => {
              setCurrentGroup(item);
              toggleMenu();
              if(showRecap) toggleShowRecap();
            }}
            style={{height: 'auto'}}
          />
        </div>
     {/* )} */}

      <div className={menuFooterClassName}>

        {showRecap
          ? (
            <div className={styles.buttonContainer}>
              <button onClick={() => {
                toggleShowRecap();
                toggleMenu();
              }}>
                <Stack gap="medium" valign="center" halign="center">
                  <BackIcon />
                  <Label>{t('button.configurator')}</Label>
                </Stack>
              </button>
            </div>
          ) : (
            <div className={styles.buttonContainer}>
              <button onClick={() => viewer.startAr() }>
                <Stack gap="medium" valign="center" halign="center">
                  <VirtualTourIcon />
                  <Label>{t('button.ar')}</Label>
                </Stack>
              </button>
            </div>
          )}

        <div className={styles.pipe}/>
        <div className={styles.buttonContainer}>
          <button onClick={() => {toggleTheme(); toggleMenu();}}>
            <Stack gap="medium" valign="center" halign="center">
              <ThemeIcon />
              <Label>{t('button.changeTheme')}</Label>
            </Stack>
          </button>
        </div>
      </div>
    </>
);
}
