import {useEffect} from "react";
import useAppStore from "./useAppStore";
import bikes from "../configurations/bikes";

const emptyCustomText = {
  right: {
    materialVariation : 'invisible',
    configurableMaterial : `custom_text_right`
  },
  left: {
    materialVariation : 'invisible',
    configurableMaterial : `custom_text_left`
  }
};

const emptySignaturePatch = {
  right: {
    materialVariation : 'invisible',
    configurableMaterial : `signature_patch_right`
  },
  left: {
    materialVariation : 'invisible',
    configurableMaterial : `signature_patch_left`
  }
};

function clearText(viewer, side) {
  viewer.setMaterials({
    materials : [
      emptyCustomText[side]
    ]
  }).then()
    .error(error => console.log(error));
}

function clearAll(viewer, side) {
  viewer.setMaterials({
    materials : [
      emptyCustomText[side],
      emptySignaturePatch[side],
    ]
  }).then()
    .error(error => console.log(error));
}

function clearPatch(viewer, side) {
  viewer.setMaterials({
    materials : [
      emptySignaturePatch[side]
    ]
  }).then()
    .error(error => console.log(error));
}

export function configureText(viewer, side, saddleConfiguration){
  if(
    saddleConfiguration.text === '' ||
    saddleConfiguration.textColor === null
  ) return;

  viewer.setMaterials({
    materials : [{
      materialVariation : 'custom_text',
      configurableMaterial : `custom_text_${side}`
    }]
  }).then( result => {
    viewer.updateCustomTextsRealDimensions({
      configurableMaterial: `custom_text_${side}`,
      materialVariation: 'custom_text',
      contents : ['opacity', 'albedo'],
      color : '#ffffffff',
      backgroundColor : '#00000000',
      imageWidth : 2048,
      verticalAlignment : 'middle',
      textAlignment : 'static',
      horizontalAlignment : side === 'right' ? 'left' : 'right',
      textOffsetX : 0,
      textOffsetY : 0,
      uvRatio : 10.34,
      surfaceWidth :190.35,
      maxTextWidth :190,
      maxTextHeight :10,
      font : 'OpenSans-Semibold',
      mode : 'vertical',
      texts : [{
        color : saddleConfiguration.textColor.color,
        verticalAlignment : 'middle',
        horizontalAlignment : 'middle',
        offsetY : 0,
        offsetX : 0,
        text : saddleConfiguration.text,
        font : 'OpenSans-Semibold',
        prespacing : 0,
        postspacing : 0
      }]
    })
      .then()
      .error( error => console.log(error));
  }).error( error => console.log(error));
}

function configureImage(viewer, side, saddleConfiguration) {

  if(saddleConfiguration.imageFile.length === 1){
    const file = saddleConfiguration.imageFile[0].file
    const url = saddleConfiguration.imageFile[0].preview;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64 = reader.result;
      viewer.setMaterials({
        materials : [{
          configurableMaterial : `signature_patch_${side}`,
          materialVariation : 'custom',
        }]})
        .then( result => {
          viewer.updateCustomTexture({
            configurableMaterial : `signature_patch_${side}`,
            materialVariation : 'custom',
            image : base64,
            contents : ['baseColor', 'opacity']
          }).then()
            .error( err => console.log(err));
        });
    }
  }
}

export default function useUpdateSaddleConfiguration(viewer, loaded) {

  const {saddleConfiguration, modelConfiguration} = useAppStore( (state) => ({
    saddleConfiguration: state.saddleConfiguration,
    modelConfiguration: state.modelConfiguration,
  }));
  
  const singleSelection =
    modelConfiguration.name === bikes.flusso ||
    modelConfiguration.name === bikes.dolomia;
  
  useEffect(function (){
    if(viewer === null) return;
    if(loaded === false) return;

    const rightSelection = saddleConfiguration.right.selection;
    const leftSelection = saddleConfiguration.left.selection;

    const rightSignatureColor = saddleConfiguration.right.signatureColor;

    if(rightSelection === null && leftSelection === null) {
      clearAll(viewer, 'right');
      clearAll(viewer, 'left');
      return;
    }
    
    //Use Mario Cipollini signature image
    if(rightSelection === 'signature' && rightSignatureColor !== null) {
      if(saddleConfiguration.right.base64 !== null) clearPatch(viewer, 'right');
      
      if(singleSelection === true) clearText(viewer, 'right');
      
      viewer.setMaterials({
        materials : [{
          configurableMaterial : `signature_patch_right`,
          materialVariation : `mario_cipollini_${rightSignatureColor.name}`,
        }]
      });
    }

    // updateCustomTexture
    // custom_patch_right | custom_patch_left
    if(rightSelection === 'text') {
      if(
        singleSelection === true &&
        saddleConfiguration.right.text !== '' &&
        saddleConfiguration.right.textColor !== null
      ) {
        clearPatch(viewer, 'right');
      }
      
      if(saddleConfiguration.right.text === '') clearText(viewer, 'right');
      else configureText(viewer, 'right', saddleConfiguration.right);
    }

    if(leftSelection === 'text') {
      if(
        singleSelection === true &&
        saddleConfiguration.left.text !== '' &&
        saddleConfiguration.left.textColor !== null
      ) {
        clearPatch(viewer, 'left');
      }
      if(saddleConfiguration.left.text === '') clearText(viewer, 'left');
      else configureText(viewer, 'left', saddleConfiguration.left);
    }

    // update custom uploaded image
    if(rightSelection === 'image') {
      if(singleSelection === true && saddleConfiguration.right.base64 !== null) {
          clearPatch(viewer, 'right');
          clearText(viewer, 'right');
      }
      configureImage(viewer, 'right', saddleConfiguration.right)
    }

    if(leftSelection === 'image') {
      if(singleSelection === true && saddleConfiguration.left.base64 !== null) {
        clearText(viewer, 'left');
      }
      configureImage(viewer, 'left', saddleConfiguration.left);
    }
    
  }, [viewer, loaded, saddleConfiguration]);


}
