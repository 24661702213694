import * as React from "react"

export default (props) => (

  <svg
    width={164}
    height={46}
    {...props}
  >
    <path
      fill="#e7334f"
      d="M60.481 13.169s-.583.027-.929.16c-.806.33-2.269 1.345-3.606 4.463-.941 2.195-2.229 5.232-3.394 7.983-.552-2.268-1.096-4.495-1.502-6.155l-.001-.008c-.429-1.755-.485-3.111-.394-4.117.315-2.33 1.592-2.327 1.592-2.327l-3.857-.002-.001.002H45.25a.848.848 0 0 0-.145.015s-1.105-.113-1.287 2.32a2.847 2.847 0 0 0-.331-.355c-1.556-1.38-3.643-1.979-6.192-1.979-1.3.06-2.583.164-3.91.494-1.332.33-2.596.887-3.799 1.667-.658-.9-1.605-1.544-2.75-1.845-1.147-.3-1.564-.309-2.897-.309-.506.023-1.03.085-1.568.174-1.382.253-3.032.858-3.032.858a3.79 3.79 0 0 1 1.038-.876c.028-.017.057-.032.085-.048.176-.096.292-.111.292-.111h-6.406s-.583.027-.929.16c-.807.33-2.269 1.314-3.606 4.433-2.189 5.102-6.258 14.769-6.258 14.769S2.019 36.368.502 37.721H6.73a2.04 2.04 0 0 0 1.221-.398c.631-.501 1.592-1.642 2.702-4.226a2231.78 2231.78 0 0 0 4.657-10.972c.378-.726.823-1.451 1.302-1.99a8.14 8.14 0 0 1 .975-.774c.145-.082.263-.159.358-.23.007-.005.015-.011.023-.015 1.093-.661 2.162-.991 3.206-.991 1.867 0 1.698 1.65 1.275 2.951a837.52 837.52 0 0 0-.656 1.545l-.001.002c-2.031 4.788-4.223 9.992-4.223 9.992s-1.535 3.833-3.052 5.186h6.229c.627 0 1.061-.275 1.221-.397.631-.502 1.592-1.643 2.702-4.227 1.276-2.972 3.188-7.492 4.563-10.75.361-.727.794-1.476 1.267-2.065a7.72 7.72 0 0 1 1.597-1.245c1.076-.634 2.116-.959 3.12-.987 1.67.062 1.592 1.534 1.22 2.778-2.194 5.159-4.943 11.689-4.943 11.689s-1.536 3.832-3.052 5.185h6.229c.626 0 1.06-.275 1.221-.398.631-.501 1.592-1.64 2.702-4.226 1.648-3.841 4.362-10.27 5.602-13.213l.018.082c.235 1.264.582 2.757 1.075 4.531.634 2.618 1.331 5.48 1.878 7.716.286 1.192.613 2.547.95 3.938a3827.253 3827.253 0 0 0-3.053 7.217c-.843 1.841-3.043 6.486-4.253 7.565h6.229c.178 0 .338-.023.482-.059.481-.062.815-.275.951-.379.632-.501 1.592-1.642 2.701-4.227.349-.813.746-1.744 1.167-2.73.057-.126.112-.251.163-.37 1.3-3.027 3.262-7.665 4.642-10.935 2.245-5.25 5.902-13.94 5.902-13.94s1.687-4.326 3.842-5.185h-6.405z"
    />
    <text
      fill="#FFF"
      fontFamily="'ArialMT'"
      fontSize={4.5}
      transform="matrix(1.64 0 0 1 56.349 45.142)"
    >
    </text>
    <text
      fill="#FFF"
      fontFamily="'ArialMT'"
      fontSize={4.5}
      transform="matrix(1.64 0 0 1 1.456 45.142)"
    >
    </text>
    <path
      fill="#FFF"
      d="M89.691 33.173c-2.479-.005-2.857-1.83-2.401-4.065a942.19 942.19 0 0 1-1.702 3.991c-.895 2.086-1.688 3.224-2.291 3.849 1.223.517 2.724.793 4.457.793 5.721 0 12.29-2.078 14.713-8.035h-6.865c-1.086 2.26-3.167 3.467-5.911 3.467zm8.462-19.997c-.731 0-1.423.005-2.076.021-2.424.461-4.238 5.149-4.238 5.149s-.64 1.524-1.552 3.681c1.475-2.339 3.438-4.28 5.931-4.274 2.681-.009 3.774 1.198 3.032 3.335h6.867c2.614-5.948-2.24-7.899-7.964-7.912z"
    />
    <path
      fill="#E53138"
      d="M69.774 13.187s-.582.027-.927.161c-.807.33-2.267 1.343-3.603 4.458-2.186 5.097-6.251 14.753-6.251 14.753s-1.534 3.828-3.049 5.179h6.222c.627 0 1.06-.275 1.22-.397.63-.501 1.59-1.639 2.699-4.221 2.186-5.096 6.25-14.753 6.25-14.753s1.685-4.321 3.838-5.179l-6.399-.001zM78.744 13.187s-.583.027-.928.161c-.806.33-2.267 1.343-3.602 4.458-2.186 5.097-6.25 14.753-6.25 14.753s-1.534 3.828-3.049 5.179h6.222c.626 0 1.06-.275 1.219-.397.63-.501 1.59-1.639 2.698-4.221 2.188-5.096 6.251-14.753 6.251-14.753s1.685-4.321 3.838-5.179l-6.399-.001zM87.712 13.187s-.582.027-.929.161c-.806.33-2.266 1.343-3.602 4.458-2.185 5.097-6.25 14.753-6.25 14.753s-1.535 3.828-3.049 5.179h6.223c.627 0 1.06-.275 1.219-.397.631-.501 1.591-1.639 2.699-4.221 2.187-5.096 6.25-14.753 6.25-14.753s1.685-4.321 3.838-5.179l-6.399-.001z"
    />
    <path
      fill="#FFF"
      d="m101.261 37.266 10.01-23.651 6.551.01-10.005 23.641zM110.78 37.266l10.009-23.642h10.874c5.797 0 8.808 1.868 6.382 7.6-2.428 5.732-7.017 7.601-12.812 7.592h-4.323l-3.577 8.45h-6.553zm15.439-13.036c2.635-.099 4.213-1.277 4.947-3.006.736-1.738.153-2.915-2.395-3.015H125.4l-2.551 6.021h3.37zM137.777 25.47c3.3-7.79 9.635-12.312 17.461-12.303 7.828 0 10.337 4.514 7.044 12.311-3.306 7.799-9.642 12.312-17.468 12.312-7.828-.001-10.338-4.529-7.037-12.32m15.523-7.725c-4.452 0-7.121 4.126-8.688 7.832-1.482 3.501-2.31 7.627 2.141 7.627 4.455 0 7.121-4.126 8.604-7.627 1.569-3.706 2.393-7.832-2.057-7.832"
    />
  </svg>
);
