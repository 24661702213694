import styles from './Progress.module.css';

export default function Progress({
  progress= 0,
  loading= false,
}) {

  const progressClassName = `${styles.progress} ${loading === true ? styles.loading : null}`;

  return (
    <div className={styles.container}>
      <div className={progressClassName} style={{width: `${Math.min(progress, 100)}%`}} />
    </div>
  );

}
