{
  "button": {
    "back": "indietro",
    "cancel": "annulla",
    "next": "avanti",
    "confirm": "prosegui",
    "ar": "Augmented reality",
    "changeTheme": "Cambia sfondo",
    "reset": "reset",
    "purchase": "richiedi preventivo",
    "backToConfigurator": "torna al configuratore",
    "configurator": "configuratore",
    "skipstep": "annulla",
    "apply": "salva e chiudi"
  },
  "text": {
    "notice": "attenzione",
    "reset": "EFFETTUANDO IL RESET VERRANNO CANCELLATE LE CONFIGURAZIONI SIN QUI SALVATE",
    "design-reset": "MODIFICANDO IL DESIGN VERRANNO CANCELLATE LE CONFIGURAZIONI SIN QUI SALVATE",
    "color-warning": "Attenzione: la resa colore a schermo potrebbe differire dal colore reale. Le varianti metalliche o gli effetti speciali sono difficilmente replicabili a schermo; ti invitiamo a contattare un nostro punto vendita autorizzato per visionare di persona la tabella colori. Eventuali imperfezioni sulle colorazioni Chromo sono da attribuire allo specifico processo produttivo e non modificabili.\n\nCipollini si riserva la facoltà di non accettare eventuali contestazioni derivanti dalla configurazione scelta dal cliente sul My Cipo."
  },
  "label": {
    "monocromo": "MONOCOLORE",
    "multicolor": "MULTICOLORE",
    "spectaflair": "SPECTRAFLAIR",
    "shiny": "LUCIDO",
    "matt": "OPACO",
    "carbon": "CARBON",
    "silver": "SILVER",
    "dark grey": "DARK GREY",
    "antracite": "ANTRACITE",
    "ocher": "OCHER",
    "gold": "GOLD",
    "lime": "LIME",
    "sand": "SAND",
    "ottanium": "OTTANIUM",
    "biscay bay": "BISCAY BAY",
    "dark blue": "DARK BLUE",
    "cobalt blue": "COBALT BLUE",
    "fuchsia": "FUCHSIA",
    "purple": "PURPLE",
    "pink": "PINK",
    "bordeaux": "BORDEAUX",
    "red metal": "RED METAL",
    "orange": "ORANGE",
    "red": "RED",
    "yellow": "YELLOW",
    "pearl white": "PEARL WHITE",
    "iridescent green": "IRIDESCENT GREEN",
    "iridescent blue": "IRIDESCENT BLUE",
    "iridescent pink": "IRIDESCENT PINK",
    "graphite blue": "GRAPHITE BLUE",
    "graphite red": "GRAPHITE RED",
    "chromo blue": "CHROMO BLUE",
    "chromo red": "CHROMO RED",
    "crystal green": "CRYSTAL GREEN",
    "crystal black": "CRYSTAL BLACK",
    "crystal": "CRYSTAL",
    "red dot": "RED DOT",
    "green dot": "GREEN DOT",
    "green bottle": "GREEN BOTTLE",
    "white": "BIANCO",
    "black": "NERO"
  },
  "material": {
    "design": "schema colore",
    "paint": "finitura locale",
    "telaio": "colore principale",
    "stickers": "colore 2° livello",
    "linee": "colore 3° livello",
    "loghi": "loghi",
    "testo-immagine": "testo/firma"
  }
}
