import ConfigurationItem from "./ConfigurationItem";
import Stack from "../atoms/Stack";
import useAppStore from "../hooks/useAppStore";
import {itemStatus} from "./ConfigurationItem";
import {useTranslation} from "react-i18next";
import {useCallback} from "react";

export default function MaterialsMenu({
  items,
  onSelect,
  ...props
}) {

  const {currentGroup, currentMaterials, modelConfiguration} = useAppStore( state => ( {
    currentGroup: state.currentGroup,
    currentMaterials: state.currentMaterials,
    modelConfiguration: state.modelConfiguration,
  }) );

  const getDisabled = useCallback(function (index) {
    if (index === 0) return false;

    return currentMaterials[index - 1] === undefined;
  }, [currentMaterials]);


  const getStatus = useCallback(function (item) {
    if(currentGroup === null) return itemStatus.todo;

    return currentMaterials.find( material => material.group.id === item.id) !== undefined
      ? itemStatus.done
      : itemStatus.todo

  }, [currentGroup, currentMaterials]);

  return (
    <Stack gap='none' {...props}>
      {items.map( (item, idx) => (
        <ConfigurationItem
          key={idx}
          index={idx+1}
          item={item}
          active={currentGroup && currentGroup.id === item.id}
          status={getStatus(item)}
          disabled={getDisabled(idx)}
          onSelect={onSelect}
        />
      ))}
    </Stack>
  );
};
