import styles from "../templates/MainTemplate.module.css";
import Stack from "../atoms/Stack";
import Inline from "../atoms/Inline";
import Label from "../atoms/Label";
import Button from "../atoms/Button";
import TextIconButton from "../molecules/TextIconButton";
import VariantSelector from "../templates/VariantSelector";
import Navigation from "./Navigation";
import useAppStore from "../hooks/useAppStore";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import EmersyaViewerContext from "../contexts/EmersyaViewerContext";
import {useTranslation} from "react-i18next";
import * as utils from "../utils/materials";
import BackIcon from "../icons/BackIcon";
import { createPortal } from "react-dom";
import awsConfig from "../awsConfig.json";

export default function Footer({
  ...props
}) {

  const [saving, setSaving] = useState(false);
  
  const { t } = useTranslation();

  const {
    showRecap, modelConfiguration, currentGroup, currentMaterials,
    productId, modelId, saddleConfiguration, toggleShowRecap, description} = useAppStore( (state) => ({
    showRecap: state.showRecap,
    modelConfiguration: state.modelConfiguration,
    currentGroup: state.currentGroup,
    currentMaterials: state.currentMaterials,
    productId: state.productId,
    modelId: state.modelId,
    saddleConfiguration: state.saddleConfiguration,
    toggleShowRecap: state.toggleShowRecap,
    description: state.description
  }));
  
  //shiny | matt
  const paint = utils.getMaterialByGroupId(currentMaterials, 'paint');
  
  const eventPayload = useCallback(function(){
    return {
      modelId,
      modelName: modelConfiguration.name,
      productId,
      description,
      
      configuration: currentMaterials
        .filter(material => material.group.id !== 'text-image')
        .map(material => ({
          groupName: material.group.name,
          configuration: {
            color: material.configuration.color,
            name: paint && paint.configuration.id === 'matt'
              ? material.configuration.name.replace(' Shiny', ' Matt')
              : material.configuration.name,
            code: (
              paint && paint.configuration.id === 'matt' &&
              material.configuration.code !== null &&
              material.configuration.code !== ''
                ? `${material.configuration.code.substring(0, material.configuration.code.length-1)}M`
                : material.configuration.code
            ),
            image: material.configuration.image,
          }
        })).concat({
          groupName: 'text-image',
          configuration: { ...saddleConfiguration }
        }),
    }
  }, [currentMaterials, modelId, modelConfiguration, saddleConfiguration, description, productId, paint]);
  
  const total = modelConfiguration.configurableGroups.length;

  const emersyaViewerContext = useContext(EmersyaViewerContext);
  const viewer = emersyaViewerContext.viewer;

  const currentIndex = currentGroup === null
    ? 0
    : modelConfiguration.configurableGroups.findIndex(group => group.name === currentGroup.name);

  async function captureScreenshot() {
    return viewer.getScreenshot({
      width: 512,
      height: 512,
      takeBackground: false,
      camera: modelConfiguration.screenshotCamera
    })
  }
  
  async function askForQuote() {
    
    setSaving(true);
    
    const image = await captureScreenshot();
    const lambdaResponse = await fetch(awsConfig.lambda_endpoint_url);
    const lambdaData = await lambdaResponse.json();
    
    try {
      // Upload configuration with fetch API
      const response = await fetch(lambdaData.presignedUploadUrl, {
        method: "PUT",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(eventPayload()), // body data type must match "Content-Type" header
      });
      
      if(! response.ok) {
        throw new Error(`Error uploading configuration file, status code ${response.status}`);
      }
      
      // Dispatch Custom Event
      const onRequestQuote = new CustomEvent('onRequestQuote', {
        detail: {
          image,
          ...eventPayload(),
          viewerUrl: `${awsConfig.cdn_configurator_url}/readonly.html?uuid=${lambdaData.uuid}`
        }
      });
      
      const target = window.CipolliniConfigurator
        ? document.getElementById(window.CipolliniConfigurator.elementId)
        : window;
      target.dispatchEvent(onRequestQuote);
      
      console.log('onRequestQuote event dispatched');
      console.log(onRequestQuote);
      
      setSaving(false);
      
    } catch(error) {
      console.error(error);
      setSaving(false);
    }
    
  }

  useEffect(function (){
    if(! showRecap) return;
    if(viewer === null) return;

    viewer.play().then( data => console.log('play started', data) );
  }, [viewer, showRecap]);


  if(showRecap) {
    return (
      <div className={styles.purchaseFooter}>
        <Stack valign="space-between" halign="center">
          <TextIconButton icon="virtualTour" text="AUGMENTED REALITY" onClick={() => viewer.startAr().then(data => console.log(data))}/>
          <Label size="large" weight="bold">{modelConfiguration.name}</Label>

          <div className={styles.footerButtonContainer}>
            <Inline align="center" verticalAlign="center">
              <Button onClick={askForQuote}>
                {t('button.purchase')}
              </Button>
            </Inline>
          </div>
        </Stack>
        
        {saving === true && createPortal(
          <div style={{
            position: 'absolute',
            zIndex: 10,
            inset: 0,
            backgroundColor: '#000',
            opacity: '.6',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <span className={styles.loader}></span>
          </div>,
          document.body
        )}
        
      </div>
    );
  }

  return (
      <Stack valign="center" halign="center">
        <Inline>
          <Label
            size="medium"
            weight="bold"
            spacing="medium"
            style={{textTransform: 'uppercase'}}
          >
            {currentGroup && t(`material.${currentGroup.name.toLowerCase()}`)}
          </Label>
          <Label
            size="medium"
            weight="medium"
            color="#979797"
          >
            {currentIndex + 1} | {total}
          </Label>
        </Inline>

        <div className={styles.variantSelector}>
          <VariantSelector />
        </div>
        <Navigation />
      </Stack>
  );
}
