import styles from "../templates/MainTemplate.module.css";
import Inline from "../atoms/Inline";
import Label from "../atoms/Label";
import useAppStore from "../hooks/useAppStore";
import Stack from "../atoms/Stack";

export default function ProductInfo({
  name,
  ...props
}) {

  const {showRecap, showARModal, description} = useAppStore( (state) => ({
    showRecap: state.showRecap,
    showARModal: state.showARModal,
    description: state.description,
  }));

  const productInfoClassName = showARModal ? `${styles.productInfo} ${styles.hide}` : `${styles.productInfo}`;

  return (
    showRecap === false && (
      <div className={productInfoClassName}>
        <div className={styles.productInfoContainer}>
          <Label size="large" weight="medium">{name}</Label>
          <Label weight="medium" color="#8b8b8b">{description}</Label>
        </div>
      </div>
    )
  );
}
