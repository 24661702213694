import adone from "./adone";
import flusso from "./flusso";
import theone from "./theone";
import dolomia from "./dolomia";

export default {
  //masterSku: model name
  M0012MC122ADONE_DB: adone,
  M0012MC122DOLOMIA_DB: dolomia, //M0012MC123DOLOMIA_DB
  M0012MC123FLUSSO_DB: flusso,
  M0012MC123RB1KONE_DB: theone,
}
