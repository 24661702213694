// @todo,
// use thi API to prevent warning.

/*
viewer.setMaterials({
  materials: [{
    materialVariation: materialGroup.configuration.name,
    configurableMaterial: 'base'
  }]
}).then()
  .error();
*/

import {useEffect} from "react";
import useAppStore from "./useAppStore";
import * as utils from "../utils/materials";

const MATERIAL_SELECTORS = ['color'];

const materialName = (paint, material) => {

  if(! paint) return `${material} Shiny`

  return paint.configuration.id === 'matt'
    ? `${material} Matt`
    : `${material} Shiny`
}

export default function useSetMaterial(viewer, loaded) {

  const {currentMaterials, toggleApplyingMaterials, currentGroup, setCurrentMaterials} = useAppStore( (state) => ({
    toggleApplyingMaterials: state.toggleApplyingMaterials,
    currentMaterials: state.currentMaterials,
    setCurrentMaterials: state.setCurrentMaterials,
    currentGroup: state.currentGroup,
  }));
  
  
  useEffect(function (){
    if(viewer === null) return;
    if(loaded === false) return;
    
    let materialsGroups = [];
    
    // Spectraflair design
    if(currentMaterials.find( material =>
      material.group.id === 'design' &&
      material.configuration.id === 'spectaflair'
    )) {
      materialsGroups = materialsGroups.concat([{
        configurationName: 'Spectraflair 1',
        groupName: 'Telaio'
      }, {
        configurationName: 'Spectraflair 1',
        groupName: 'Stickers'
      }, {
        configurationName: 'Spectraflair 1',
        groupName: 'Linee'
      }]);
    }
    
    if(currentMaterials.length === 0) {
      toggleApplyingMaterials();
      viewer.resetMaterials()
        .then(data => toggleApplyingMaterials())
        .error(err => toggleApplyingMaterials());
      return;
    }

    toggleApplyingMaterials();

    //Exclude DESIGN and PAINT. They are not material, but are visible on Material Menu
    const filteredCurrentMaterial = currentMaterials.filter(material =>
      material.group.id !== 'design' &&
      material.group.id !== 'paint' &&
      material.group.id !== 'text-image' &&
      MATERIAL_SELECTORS.indexOf(material.group.selectorType) >= 0
    );

    const paint = utils.getMaterialByGroupId(currentMaterials, 'paint');
    
    materialsGroups = materialsGroups.concat(filteredCurrentMaterial.map(material => ({
      configurationName: materialName(paint, material.configuration.name),
      groupName: material.group.name,
    })));

    let extraMaterialGroups = [];
    const design = utils.getMaterialByGroupId(currentMaterials, 'design');
    
    // monocromo design require that Telaio (Main Color) and Linee (Level color 3°) have the same color
    if(
      design &&
      currentGroup.name === 'Telaio' &&
      design.configuration.id === 'monocromo'
    ) {
      const mainColor = utils.getMaterialByGroupName(currentMaterials, 'Telaio');
      extraMaterialGroups = [{
        configurationName: materialName(paint, mainColor.configuration.name),
        groupName: 'Linee'
      },{
        configurationName: materialName(paint, mainColor.configuration.name),
        groupName: 'Stickers'
      }
      ];
    }
    
    if(materialsGroups.length === 0) {
      toggleApplyingMaterials();
      return;
    }
    
    // change multiple groups of materials at once, based on predefined configurations from the material groups.
    // this API call generate a warning
    viewer.setMaterialsGroups({
      configurations: materialsGroups.concat(extraMaterialGroups)
    })
      .then( data => toggleApplyingMaterials() )
      .error( err => {console.log(err); toggleApplyingMaterials();});

  }, [viewer, loaded, currentMaterials]);

}
