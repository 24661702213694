import Progress from "../atoms/Progress";
import Logo from "../icons/Logo";
import Stack from "../atoms/Stack";
import styles from './Loading.module.css';

export default function Loading({
  progress = 0,
}) {

  return (
    <Stack halign={"center"} valign={"center"} gap="xlarge">
      <Logo className={styles.loading} />
      <Progress loading={true} progress={progress} />
    </Stack>
  );
}
