import {createStore} from "zustand";
import {createConfiguratorSlice} from "./createConfiguratorSlice";
import {createApplicationSlice} from "./createApplicationSlice";
import models from "../configurations/models";
import { getMaterialByGroupId } from "../utils/materials";

export default function storeCreator({
  modelId= 'M0012MC122ADONE_DB',
  materials= [],
  lang= 'en',
  ...props
}) {

  const configurableGroups = [
    ...models[modelId].configurableGroups,
  ];


  //Init 3d models with this materials configuration
  const currentMaterials = materials.reduce( (acc, current, idx, arr) => {
    const group = models[modelId].configurableGroups.find( group => group.name === current.groupName);
    let result = [...acc];
    if(group !== undefined) {
      const configuration = group.configurations.find( configuration => configuration.name === current.configuration.name);
      if(configuration !== undefined) {
        result = [...result, {
          group,
          configuration,
        }];
      }
    }
    return result;
  }, []);
  
  
  return createStore( (state)  => {
    const store = {
      ...createConfiguratorSlice(state),
      ...createApplicationSlice(state),
      modelId,
      modelConfiguration: {
        ...models[modelId],
        configurableGroups,
      },
      currentMaterials,
      lang,
      ...props,
    };
    
    // verify init configuration for saddle
    const saddleConfiguration = materials.find( material => material.groupName === 'text-image');
    if(saddleConfiguration !== undefined) {
      store.saddleConfiguration = {
        ...saddleConfiguration.configuration
      };
    }
    console.log("STORE", store);
    
    return store;
  });
}
