.size-small {
  composes: size-small from '../style.module.css';
}

.size-normal {
  composes: size-normal from '../style.module.css';
}

.size-medium {
  composes: size-medium from '../style.module.css';
}

.size-large {
  composes: size-large from '../style.module.css';
}

.spacing-normal {
  composes: spacing-normal from '../style.module.css';
}

.spacing-medium {
  composes: spacing-medium from '../style.module.css';
}

.spacing-large {
  composes: spacing-large from '../style.module.css';
}

.spacing-xlarge {
  composes: spacing-xlarge from '../style.module.css';
}

.weight-normal {
  composes: book from '../style.module.css';
}

.weight-medium {
  composes: medium from '../style.module.css';
}

.weight-bold {
  composes: bold from '../style.module.css';
}

.weight-strong {
  composes: black from '../style.module.css';
}
