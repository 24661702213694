import RadioButton from "../atoms/RadioButton";
import Stack from "../atoms/Stack";
import styles from './SaddleSelector.module.css';
import ColorSelector from "../molecules/ColorSelector";
import TextInput from "../atoms/TextInput";
import Inline from "../atoms/Inline";
import Label from "../atoms/Label";
import ImageUploader from "../molecules/ImageUploader";
import useAppStore from "../hooks/useAppStore";
import {useContext, useEffect, useState} from "react";
import EmersyaViewerContext from "../contexts/EmersyaViewerContext";
import bikes from "../configurations/bikes";

export const saddleColors = [
  {id: 1, name: "white", code: null, color: "#ffffff"},
  {id: 2, name: "silver", code: null, color: "#515050"},
  {id: 4, name: "black", code: null, color: "#2b2b2b"}
];

export default function SaddleSelector({
 side,
 onSideSelect,
}) {

  const {
    saddleConfiguration,
    updateSaddleConfiguration,
    modelConfiguration,
  } = useAppStore( (state) =>  ({
      saddleConfiguration: state.saddleConfiguration,
      updateSaddleConfiguration: state.updateSaddleConfiguration,
      modelConfiguration: state.modelConfiguration
  }));

  const {viewer, loaded, progress} = useContext(EmersyaViewerContext);

  useEffect(function() {
    if (viewer === null) return;
    if (loaded === false) return;

    viewer.setCamera({
      camera: modelConfiguration.saddleCamera[side]
    });

  }, [viewer, loaded, side]);

  const { text, textColor, imageFile} = saddleConfiguration[side];

  const signatureSelected = saddleConfiguration[side].selection === 'signature';
  const textSelected = saddleConfiguration[side].selection === 'text';
  const imageSelected = saddleConfiguration[side].selection === 'image';

  const signatureColor = saddleConfiguration[side].signatureColor;
  const signatureColorContainerClassName = `${styles.signatureColorContainer} ${signatureSelected ? styles.signatureContainerOpened : null}`;
  const textContainerClassName = `${styles.textContainer} ${textSelected ? styles.textContainerOpened : null}`;
  const imageUploadContainerClassName = `${styles.imageUploaderContainer} ${imageSelected ? styles.imageUploaderContainerOpened : null}`;
  
  const singleSelection =
    modelConfiguration.name === bikes.flusso ||
    modelConfiguration.name === bikes.dolomia;
  
  
  const getSignatureSaddleConfiguration = (item, side) => {
    if(singleSelection === true) {
      return {
        ...saddleConfiguration,
        [side]: {
          ...saddleConfiguration[side],
          text: '',
          textColor: null,
          signatureColor: item,
          signature: null,//signatureBase64(item),
          imageFile: [],
          base64: null,
        }
      };
    }
    
    return {
      ...saddleConfiguration,
      [side]: {
        ...saddleConfiguration[side],
        signatureColor: item,
        signature: null,//signatureBase64(item),
        imageFile: [],
        base64: null,
      }
    };
  };
  
  const getTextSaddleConfiguration = (item, side) => {
    
    if(singleSelection === true) {
      
      if(side === 'right') {
        return {
          ...saddleConfiguration,
          right: {
            ...saddleConfiguration.right,
            textColor: item,
            signatureColor: null,
          }
        };
      } else {
        return {
          ...saddleConfiguration,
          left: {
            ...saddleConfiguration.left,
            textColor: item,
            imageFile: [],
            base64: null,
          }
        };
      }
    }
    
    return {
      ...saddleConfiguration,
      [side]: {
        ...saddleConfiguration[side],
        textColor: item
      }
    };
  }
  
  const getImageSaddleConfiguration = (image, base64, side) => {
    
    let nextConfiguration = {
      ...saddleConfiguration,
      [side]: {
        ...saddleConfiguration[side],
        imageFile: [image],
        base64,
      }
    };
    
    if(singleSelection === true) {
      
      if(side === 'right') {
        nextConfiguration = {
          ...nextConfiguration,
          right: {
            ...nextConfiguration.right,
            signatureColor: null,
            signature: null,
            text: '',
            textColor: null
          }
        }
      } else {
        nextConfiguration = {
          ...nextConfiguration,
          left: {
            ...nextConfiguration.left,
            text: '',
            textColor: null,
          }
        }
      }
      
      return nextConfiguration;
    }
    
    if(side === 'right') {
      nextConfiguration.right = {
        ...nextConfiguration.right,
        signatureColor: null,
        signature: null,
      }
    }
    
    return nextConfiguration;
  }
  
  return (
    <>
      <Stack halign="center" gap="xlarge" style={{marginBottom: "2.4rem"}}>
        <Inline gap="large" >
          <div
            className={`${styles.side} ${side === 'right' ? styles.activeSide : null}`}
            onClick={ (event) => {
              onSideSelect('right');
            }}
          >
            <Label size="small" spacing="medium">LATO DESTRO</Label>
          </div>
          <div
            className={`${styles.side} ${side === 'left' ? styles.activeSide : null}`}
            onClick={ (event) => {
              onSideSelect('left');
            }}
          >
            <Label size="small" spacing="medium">LATO SINISTRO</Label>
          </div>
        </Inline>
      </Stack>

      <div className={styles.mainContainer}>
        <div id="zoomed3d" className={styles.zoomed3d} />
        <div className={styles.selectionContainer}>
          {side === 'right' && (
            <>
              <RadioButton
                id='signature'
                text="AGGIUNGI LA FIRMA DI MARIO"
                checked={signatureSelected}
                onChange={ () => updateSaddleConfiguration({
                  ...saddleConfiguration,
                  right: {
                    ...saddleConfiguration.right,
                    selection: 'signature',
                  }
                })}
              />
              <div className={signatureColorContainerClassName}>
                <ColorSelector
                  items={saddleColors}
                  selected={signatureColor}
                  onSelect={ (item) => {
                    updateSaddleConfiguration(
                      getSignatureSaddleConfiguration(item, side)
                    );
                  }}
                />
              </div>
              <hr className={styles.divider} />
            </>
          )}

          <RadioButton
            id='text'
            text="AGGIUNGI UN TESTO"
            checked={textSelected}
            onChange={() => {
              let nextConfiguration = {
                ...saddleConfiguration,
                [side]: {
                  ...saddleConfiguration[side],
                  selection: 'text',
                }
              };
              updateSaddleConfiguration(nextConfiguration);
            }}
          />
          <div className={textContainerClassName}>
            <div style={{paddingBottom: '1rem'}}>
              <Label weight="normal">
                Scegli un colore per visualizzare il testo
              </Label>
            </div>
            <Inline>
              <TextInput
                style={{width: '60%'}}
                value={text}
                onChange={ (event) => {
                  const nextConfiguration = {
                    ...saddleConfiguration,
                    [side]: {
                      ...saddleConfiguration[side],
                      text: event.target.value
                    }
                  }
                  if(event.target.value === '') nextConfiguration[side].textColor = null;
                  updateSaddleConfiguration(nextConfiguration);
                }}
              />
              <Label weight="normal" spacing="normal" size="small" style={{opacity: '55%', whiteSpace: 'nowrap'}}>MASSIMO 16 CARATTERI</Label>
            </Inline>
            <div className={styles.textColorContainer}>
              <ColorSelector
                items={saddleColors}
                selected={textColor}
                onSelect={ (item) => updateSaddleConfiguration(
                  getTextSaddleConfiguration(item, side)
                )}
              />
            </div>
          </div>
          <hr className={styles.divider} />

          <RadioButton
            id='image'
            text="AGGIUNGI UN IMMAGINE"
            checked={imageSelected}
            onChange={() => {
              let nextConfiguration = {
                ...saddleConfiguration,
                [side]: {
                  ...saddleConfiguration[side],
                  selection: 'image',
                }
              };
              updateSaddleConfiguration(nextConfiguration);
            }}
          />
          <div className={imageUploadContainerClassName}>
            <ImageUploader
              onChange={ (images, base64) => {
                const image = images.pop();
                updateSaddleConfiguration(getImageSaddleConfiguration(image, base64, side));
              }}
              files={imageFile}
            />
            <Stack style={{maxWidth: '22.875rem'}}>
              <Label weight="normal" size="small" style={{opacity: '50%', lineHeight: '1rem'}}>
                Seleziona un file dal tuo computer o trascinalo in quest’area.<br />
                Formati consentiti: JPG, JPEG, PNG, GIF. Max size 3MB
              </Label>
              <Label weight="normal" size="small">
                L'uso di marchi/loghi tutelati da diritti deve essere autorizzato dai titolari degli stessi.
              </Label>
            </Stack>
          </div>

        </div>
      </div>
    </>
  );
}
