import React from 'react';
import styles from './RadioButton.module.css';
import Label from "./Label";
import Inline from "./Inline";

export default function RadioButton({
  id,
  checked=false,
  text,
  onChange
}) {

  return (
    <Inline>
      <input type="radio" id={id} checked={checked} readOnly />
      <div className={styles.customRadio} onClick={onChange} />
      <label htmlFor={id} className={styles.text} onClick={onChange}>{text}</label>
    </Inline>
  );
}
