export default {
  id: 'design',
  name: "Design",
  label: "Design",
  selectorType: "color",
  camera: null,
  configurations: [{
    id: 'monocromo',
    name: "Monocromo",
    code: null,
    color: "#f68c46",
    image: "https://d3kaxrfv1gts8b.cloudfront.net/MONOCROM.jpg"
  },{
    id: 'multicolor',
    name: "Multicolor",
    code: null,
    multicolor: true,
    color: null,
    image: "https://d3kaxrfv1gts8b.cloudfront.net/MULTICOLOR.jpg"
  },{
    id: 'spectaflair',
    name: "Spectaflair",
    code: "MC_SCLR_S",
    color: null,
    image: "https://d3kaxrfv1gts8b.cloudfront.net/SPECTRAFLARE.jpg"
  }]
}
