.circle {
  border-radius: 50%;
  box-sizing: border-box;
}

.small {
  composes: circle;
  width: .625rem;
  height: .625rem;
}

.medium {
  composes: circle;
  width: 1.5625rem;
  height: 1.5625rem;
}

.large {
  composes: circle;
  width: 2.5625rem;
  height: 2.5625rem;
}

.multicolor {
  background: conic-gradient(#ff0000, #fff000, #71dd45, #1943f5, #9a1be8);
}
