import {createRoot} from 'react-dom/client';
import App from "./App";
import Main from "./pages/Main";
import StoreProvider from "./StoreProvider";
import Viewer from "./pages/Viewer";

window.CipolliniConfigurator = {

  init: (elementId, options) => {
    window.CipolliniConfigurator.elementId = elementId;
    window.CipolliniConfigurator.__root = createRoot(document.getElementById(elementId));
    window.CipolliniConfigurator.__root.render(
      <StoreProvider options={options}>
        <App>
          {options.readonly === true
            ? <Viewer />
            : <Main />
          }
        </App>
      </StoreProvider>
    );

    console.log("CipolliniConfigurator react component");
  },

  unmount: () => {
    if(window.CipolliniConfigurator.__root) window.CipolliniConfigurator.__root.unmount();
  },
};
