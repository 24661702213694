.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  scale: 0;
  align-items: center;
  justify-content: center;
  z-index: -4;
  transition: all 200ms ease-in;
}



.overlay {
  display: none;
}

.overlayOpened {
  display: none;
}

.containerOpened {
  z-index: 99;
  scale: 1;
}

.modal {
  opacity: 0;
  z-index: -4;
  scale: 0;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  border: 1px solid #979797;
  background-color: var(--menu-footer-background-color);
  padding: 2.8rem 0 1rem 0;
  transition: all 200ms ease-in;
  transition-delay: 80ms;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.modalOpened {
  opacity: 1;
  z-index: 4;
  scale: 1;
}

.modalContent {
  width: 18rem;
}

.buttonClose {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0.6rem;
  top: 0.6rem;
}

.buttonClose:hover {
  transform: scale(1.1);
  cursor: pointer;
}


/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {

  .container {
    position: absolute;
    display: flex;
    inset: 0;
    /*height: 0;*/
    align-items: center;
    justify-content: center;
    z-index: -4;
    transition: all 200ms ease-in;
    overflow-y: hidden;
  }

  .containerOpened {
    /*top: 0;
    bottom: 0;
    height: auto;*/
    scale: 1;
    overflow-y: auto;
    z-index: 99;
  }

  
  .overlay {
    display: block;
    position: absolute;
    background: var(--main-container-background-color);
    opacity: 0;
    inset: 0;
    transition: all 100ms ease-in;
  }

  .overlayOpened {
    display: block;
    opacity: .6;
    inset: 0;
  }

  .modal {
    position: relative;
    bottom: unset;
    align-items: center;
    width: 80%;
    height: 80%;
    padding: 0;
    align-items: center;
  }

  .modalOpened {
    opacity: 1;
    z-index: 4;
  }

  .modalContent {
    width: 100%;
    height: auto;
    padding: 0 1.81rem;
  }

  .buttonClose {
    left: auto;
    right: 0.6rem;
  }

}
