import Label from "../atoms/Label";
import Stack from "../atoms/Stack";
import Inline from "../atoms/Inline";
import Circle from "../atoms/Circle";
import styles from "../pages/Main.module.css";
import React, { useContext } from "react";
import EmersyaViewerContext from "../contexts/EmersyaViewerContext";
import { useTranslation } from "react-i18next";
import useAppStore from "../hooks/useAppStore";
import * as utils from "../utils/materials";
import { getGroupByName } from "../utils/materials";

export default function Readonly({
 children
}) {
  
  const { t } = useTranslation();
  
  const { modelConfiguration, currentMaterials, saddleConfiguration } = useAppStore( (state) => ({
    modelConfiguration: state.modelConfiguration,
    currentMaterials: state.currentMaterials,
    saddleConfiguration: state.saddleConfiguration
  }));
  
  const paint = utils.getMaterialByGroupId(currentMaterials, 'paint');
  
  const configurations = currentMaterials.map( material => {
    const group = getGroupByName(modelConfiguration.configurableGroups, material.group.name);
    const config = group.configurations.find( configuration => configuration.name === material.configuration.name )
    
    return {
      material: group.name,
      variation: config.name,
      color: config.color,
      code: (
        paint && paint.configuration.id === 'matt' &&
        config.code !== null &&
        config.code !== ''
      )
        ? `${config.code.substring(0, config.code.length-1)}M`
        :  config.code,
      image: config.image
    }
  });
  
  return (
    <div style={{
      position: 'relative',
      height: '100%',
      overflowX: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{width: '100%', display: 'flex', justifyContent: 'center', padding: '.8rem'}}>
        <Label size={"large"}>{modelConfiguration.name}</Label>
      </div>
      <Stack
        align={"center"}
        style={{
          position: 'absolute',
          right: 0,
          top: 0,
          height: 'auto',
          zIndex: 2,
          width: '15rem'
        }}
      >
        {configurations.map( (configuration, idx) => (
          <Stack key={idx} style={{
            padding: '.8rem',
            width: '100%',
            borderBottom: '1px solid #dedede',
          }}>
            <Inline gap={"medium"} align={"space-between"} style={{width: '100%'}} >
              <Label color={"#ff7500"}>{t(`material.${configuration.material.toLowerCase()}`).toUpperCase()}</Label>
              {(
                (configuration.color !== '' && configuration.color !== null) ||
                (configuration.image !== '' && configuration.image !== null)
              ) && (
                <Circle
                  color={configuration.color}
                  image={configuration.image}
                  size={"medium"}
                />
              )}
            </Inline>
            <Inline gap={"medium"} align={"space-between"}  style={{width: '100%'}}>
              <Label weight={"normal"} size={"small"}>{configuration.code}</Label>
              <Label weight={"normal"} size={"small"}>{configuration.variation}</Label>
            </Inline>
          </Stack>
        ))}
        
        <Stack style={{
          padding: '.8rem',
          width: '100%',
          borderBottom: '1px solid #dedede',
          gap: '1rem',
        }}>
          <Label color={"#ff7500"}>LATO DESTRO</Label>
          {saddleConfiguration.right.signatureColor !== null && (
            <Inline gap={"medium"} align={"space-between"}  style={{width: '100%'}}>
              <Label weight={"normal"} size={"small"}>Firma Cipollini</Label>
              <Label weight={"normal"} size={"small"}>{saddleConfiguration.right.signatureColor.name}</Label>
            </Inline>
          )}
          {saddleConfiguration.right.base64 !== null && (
            <Inline gap={"medium"} align={"space-between"}  style={{width: '100%'}}>
              <Label weight={"normal"} size={"small"}>Immagine custom</Label>
              <img src={saddleConfiguration.right.base64} width={"120"} alt={"custom image"}/>
            </Inline>
          )}
          {saddleConfiguration.right.text !== '' && (
            <>
              <Inline gap={"medium"} align={"space-between"}  style={{width: '100%'}}>
                <Label weight={"normal"} size={"small"}>Testo custom</Label>
                <Label weight={"normal"} size={"small"}>{saddleConfiguration.right.text}</Label>
              </Inline>
              <Inline gap={"medium"} align={"space-between"}  style={{width: '100%'}}>
                <Label weight={"normal"} size={"small"}>Colore testo</Label>
                <Label weight={"normal"} size={"small"}>{saddleConfiguration.right.textColor.name}</Label>
              </Inline>
            </>
          )}
        </Stack>
        
        <Stack style={{
          padding: '.8rem',
          width: '100%',
          gap: '1rem',
        }}>
          <Label color={"#ff7500"}>LATO SINISTRO</Label>
          {saddleConfiguration.left.base64 !== null && (
            <Inline gap={"medium"} align={"space-between"}  style={{width: '100%'}}>
              <Stack>
                <Label weight={"normal"} size={"small"}>Immagine custom</Label>
                <a href={saddleConfiguration.left.base64} className={styles.download} download={"custom-image-left"}>download</a>
              </Stack>
              <img src={saddleConfiguration.left.base64} width={"120"} alt={"custom image"}/>
            </Inline>
          )}
          {saddleConfiguration.left.text !== '' && (
            <>
              <Inline gap={"medium"} align={"space-between"}  style={{width: '100%'}}>
                <Label weight={"normal"} size={"small"}>Testo custom</Label>
                <Label weight={"normal"} size={"small"}>{saddleConfiguration.left.text}</Label>
              </Inline>
              <Inline gap={"medium"} align={"space-between"}  style={{width: '100%'}}>
                <Label weight={"normal"} size={"small"}>Colore testo</Label>
                <Label weight={"normal"} size={"small"}>{saddleConfiguration.left.textColor.name}</Label>
              </Inline>
            </>
          )}
        </Stack>
        
      </Stack>
      {children}
    </div>
  );
}
