export function getMaterialByGroupId(materials, groupId) {
  return materials.find( material => material.group.id === groupId);
}

export function getMaterialByGroupName(materials, groupName) {
  return materials.find( material => material.group.name === groupName);
}

export function getGroupByName( configurableGroups, groupName) {
  return configurableGroups.find( configurableGroup => configurableGroup.name === groupName);
}
