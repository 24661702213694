.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.zoomed3d {
  background-color: grey;
  width: 17rem;
  height: 14.25rem;
  margin: auto;
}

.divider {
  border-bottom-color: var(--configuration-item-border-bottom-color);
  border-width: 0 0 1px 0;
  margin: 1.25rem 0;
  border-style: inset;
  width: 100%;
}

.signatureColorContainer {
  height: 0;
  overflow-y: hidden;
  opacity: 0;
  padding: 0 1.625rem;
  transition: all 200ms ease-in;
}

.signatureContainerOpened {
  height:  5.0625rem;
  opacity: 1;
  overflow-y: inherit;
  padding: 1.25rem 0 0 1.625rem;
}

.textContainer {
  height: 0;
  overflow-y: hidden;
  opacity: 0;
  padding-top: 0;
  transition: all 200ms ease-in;
}

.textContainerOpened {
  height:  auto;
  opacity: 1;
  padding-top: 1.4375rem;
  overflow-y: inherit;
}

.textColorContainer {
  padding: 2.75rem 1.625rem 0 1.625rem;
}

.imageUploaderContainer {
  height: 0;
  overflow-y: hidden;
  opacity: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 1rem;
  transition: all 200ms ease-in;
}

.imageUploaderContainerOpened {
  height: auto;
  padding-top: 1rem;
  opacity: 1;
  overflow-y: inherit;
}

.side {
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.activeSide {
  border-bottom: 2px solid var(--button-primary-background-color);
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {

  .mainContainer {
    display: flex;
    flex-direction: row;
    height: 28rem;
    gap: 1rem;
  }

  .zoomed3d {
    background-color: grey;
    width: 33rem;
    height: 100%;
    margin: unset;
  }

  .selectionContainer {
    flex-grow: 1;
    height: 100%;
  }

  .signatureColorContainer {
    padding-left: 1.625rem;
    transition: all 200ms ease-in;
  }

  .textContainer {
    padding-left: 1.625rem;
    padding-top: 0;
    transition: all 200ms ease-in;
  }

  .textContainerOpened {
    padding-top: 1.4375rem;
  }

  .textColorContainer {
    padding: 1.625rem 0 0 0;
  }

  .signatureContainerOpened {
    padding-top: 1rem;
  }

  .imageUploaderContainer {
    flex-flow: row;
  }

}

