{
  "button": {
    "back": "zurück",
    "cancel": "Stornieren",
    "next": "Fortfahren",
    "confirm": "Bestätigen",
    "ar": "Augmented reality",
    "changeTheme": "Hintergrund Ändern",
    "reset": "zurücksetzen",
    "purchase": "ask for a quote",
    "backToConfigurator": "Konfigurator",
    "configurator": "Konfigurator",
    "skipstep": "reset all",
    "apply": "save and close"
  },
  "text": {
    "notice": "Warnung",
    "reset": "BEI DER DURCHFÜHRUNG DES RESET WERDEN DIE BIS HIER GESPEICHERTEN KONFIGURATIONEN GELÖSCHT",
    "design-reset": "CHANGING THE DESIGN WILL DELETE THE CONFIGURATIONS SAVED UP TO HERE",
    "color-warning": "Caution: The color representation on screen may differ from the actual color. Metallic variations or special effects are challenging to replicate on screen; we invite you to contact one of our authorized retail points to personally view the color chart. (HIGHLIGHT THE UNDERLINED PART) Any imperfections in Chromo colorations are attributable to the specific production process and are non-modifiable.\n\nCipollini reserves the right to reject any disputes arising from the configuration chosen by the customer on My Cipo."
  },
  "label": {
    "monocromo": "MONOCROMO",
    "multicolor": "MULTICOLOR",
    "spectaflair": "SPECTRAFLAIR",
    "shiny": "SHINY",
    "matt": "MATT",
    "carbon": "CARBON",
    "silver": "SILVER",
    "dark grey": "DARK GREY",
    "antracite": "ANTRACITE",
    "ocher": "OCHER",
    "gold": "GOLD",
    "lime": "LIME",
    "sand": "SAND",
    "ottanium": "OTTANIUM",
    "biscay bay": "BISCAY BAY",
    "dark blue": "DARK BLUE",
    "cobalt blue": "COBALT BLUE",
    "fuchsia": "FUCHSIA",
    "purple": "PURPLE",
    "pink": "PINK",
    "bordeaux": "BORDEAUX",
    "red metal": "RED METAL",
    "orange": "ORANGE",
    "red": "RED",
    "yellow": "YELLOW",
    "pearl white": "PEARL WHITE",
    "black": "BLACK",
    "iridescent green": "IRIDESCENT GREEN",
    "iridescent blue": "IRIDESCENT BLUE",
    "iridescent pink": "IRIDESCENT PINK",
    "chromo blue": "CHROMO BLUE",
    "chromo red": "CHROMO RED",
    "graphite blue": "GRAPHITE BLUE",
    "graphite red": "GRAPHITE RED",
    "crystal green": "CRYSTAL GREEN",
    "crystal black": "CRYSTAL BLACK",
    "crystal": "CRYSTAL",
    "red dot": "RED DOT",
    "green dot": "GREEN DOT",
    "green bottle": "GREEN BOTTLE",
    "white": "WHITE",
    "green": "GREEN"
  },
  "material": {
    "design": "design template",
    "paint": "finishing",
    "telaio": "main color",
    "stickers": "2° level color",
    "linee": "3° level color",
    "loghi": "logos",
    "testo-immagine": "text/signature"
  }
}
