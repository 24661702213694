import React, {useContext, useEffect} from "react";
import MainTemplate from "../templates/MainTemplate";
import styles from "./Main.module.css";
import Loading from "../molecules/Loading";
import useAppStore from "../hooks/useAppStore";
import useCamera from "../hooks/useCamera";
import useSetMaterials from "../hooks/useSetMaterials";
import EmersyaViewerContext from "../contexts/EmersyaViewerContext";
import useMeshSelection from "../hooks/useMeshSelection";
import useMaterialHighlight from "../hooks/useMaterialHighlight";
import useViewerOverlayStatusUpdated from "../hooks/useViewerOverlayStatusUpdated";
import useUpdateSaddleConfiguration
  from "../hooks/useUpdateSaddleConfiguration";
import * as utils from "../utils/materials";

export default function Main({
  ...props
 }) {

  const {viewer, loaded, progress} = useContext(EmersyaViewerContext);

  const {setCurrentGroup, modelConfiguration, currentGroup, currentMaterials} = useAppStore( (state) => ({
    setCurrentGroup: state.setCurrentGroup,
    currentGroup: state.currentGroup,
    modelConfiguration: state.modelConfiguration,
    currentMaterials: state.currentMaterials
  }));

  useCamera(viewer, loaded);
  useSetMaterials(viewer, loaded);
  useMeshSelection(viewer, loaded);
  useMaterialHighlight(viewer, loaded);
  useViewerOverlayStatusUpdated(viewer, loaded);

  useUpdateSaddleConfiguration(viewer, loaded);

  useEffect(function() {
    setCurrentGroup(modelConfiguration.configurableGroups[0]);
  },[]);

  useEffect(function() {
    if(viewer === null) return;
    if(loaded === false) return;

    viewer.pause().then( data => {
      const camera = modelConfiguration.configurableGroups[0].camera;
      if(camera === null || camera === undefined) return;

      viewer.setCamera({
        camera,
      })
        .error( err => console.warn(err));
    })
      .error( err => console.warn(err));

  },[viewer, loaded]);


  useEffect(() => {
    if(viewer === null) return;
    if(loaded === false) return;

    if(currentMaterials.length === 0) return;

    const paint = utils.getMaterialByGroupId(currentMaterials, 'paint');

    const filteredCurrentMaterial = currentMaterials.filter(material => (
      material.group.id !== 'design' &&
      material.group.id !== 'paint' &&
      material.group.id !== 'text-image' &&
      ['color'].indexOf(material.group.selectorType) >= 0)
    );

    // apply Matt or Shiny color according to paint value
    viewer.setMaterialsGroups({
      configurations: filteredCurrentMaterial.map(material => ({
        configurationName:
          paint && paint.configuration.id === 'matt'
            ? `${material.configuration.name} Matt`
            : `${material.configuration.name} Shiny`,
        groupName: material.group.name,
      }))
    })
      .then( data => console.log(data))
      .error( err => console.warn(err));

  }, [viewer, loaded]);

  let configuratorClassName = `${styles.configurator} ${loaded === true ? styles.loaded : null}`

  return (
      <MainTemplate>
        <div id="cipollini3dConfigurator" className={configuratorClassName}  />
        {loaded === false &&  (
          <div className={styles.overlay}>
            <div className={styles.loadingContainer}>
              <Loading progress={progress} />
            </div>
          </div>
        )}
      </MainTemplate>
  )
}
