.fileUploadContainer {
  width: 100%;
  border: 1px dashed #696767;
}

.dropZone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 238px;
  height: 58px;
}


@media (min-width: 768px) {

  .fileUploadContainer {
    width: 238px;
  }

}
