import React from 'react';
import styles from './TextInput.module.css';

export default function TextInput({
  value,
  onChange,
  ...props
}) {

  return (
    <input
      type="text"
      onChange={onChange}
      maxLength={16}
      value={value}
      {...props}
    />
  );
}
