.container {
  overflow-x: auto;
  overflow-y: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.fadeout {
  opacity: 0;
}

.container span {
  text-transform: uppercase;
  white-space: nowrap;
}

.circleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: 50%;
  box-sizing: border-box;
  transition: all 100ms ease-in;
  text-transform: uppercase;
}

.medium {
  width: 2.81rem;
  height: 2.81rem;
}

.large {
  width: 3.81rem;
  height: 3.81rem;
}

.circleContainer:not([disabled]):hover {
  border: 1px solid var(--circle-container-hover-color);
}

.circleContainer[disabled], .circleContainer[disabled] + span  {
  opacity: .2;
}

.selected {
  composes: circleContainer;
  border: 1px solid var(--circle-container-hover-color);
}

.container button {
  color: var(--main-container-foreground-color);
}

button[disabled]:hover {
  cursor: default;
}
